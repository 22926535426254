import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    Col,
    Content,
    Layout,
    Page,
    Row,
    Space,
    Title,
    Divider,
    Input,
    Paragraph, Pill, ButtonSize,
} from '../layout/Content';
import {Modal} from '../layout/Defaults';
import {capitalizeFirstLetter} from "../../lib/Strings";
import {timeAgo} from "../../lib/Time";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {useNavigate} from "react-router-dom";

interface PropertySchema {
    type: string;
    required: boolean;
}

interface CollectionSchema {
    properties: Record<string, PropertySchema>;
}

interface QueryResult<T> {
    results: T[];
    query: {
        total_items: number;
        page: {
            index: number;
            size: number;
        };
        timing: number;
    };
}

interface PaginatedCollectionListAction<T> {
    onClick?: (item: T) => void;
    element: React.ReactNode;
}

interface PaginatedCollectionListProps<T> {
    selectedCollection: string;
    renderItem: (item: T, size?: ButtonSize) => React.ReactNode;
    actions?: PaginatedCollectionListAction<T>[];
}


const PaginatedCollectionList: React.FC<PaginatedCollectionListProps<any>> = ({
                                                                                  selectedCollection,
                                                                                  renderItem,
                                                                                  actions
                                                                              }) => {
    const [schema, setSchema] = useState<CollectionSchema | null>(null);
    const [items, setItems] = useState<any[]>([]);
    const [filter, setFilter] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(5);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [editItem, setEditItem] = useState<any | null>(null);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const navigate = useNavigate();


    const [renderSize, setRenderSize] = useState<ButtonSize>("normal");


    const fetchSchema = async (): Promise<void> => {
        try {
            const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/${selectedCollection}/schema`, {
                method: 'GET',
                credentials: 'include',
                mode: 'cors'
            });
            const result: CollectionSchema = await response.json();
            setSchema(result);
        } catch (error) {
            console.error('Error fetching schema:', error);
        }
    };

    const fetchItems = async (): Promise<void> => {
        setLoading(true);
        try {

            const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/${selectedCollection}/query`, {
                method: 'POST',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
                credentials: 'include', // This tells the browser to include cookies with the request
                body: JSON.stringify({
                    //filter:{type:"alignment"},
                    search: filter,
                    page: {
                        index: pageIndex,
                        size: pageSize
                    }
                })
            });
            const result: QueryResult<any> = await response.json();
            setItems(result.results);
            setTotalItems(result.query.total_items);
        } catch (error) {
            console.error('Error fetching items:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id: string): Promise<void> => {
        try {
            const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/${selectedCollection}/${id}`, {
                method: 'DELETE',
                credentials: 'include',
                mode: 'cors'
            });
            if (response.ok) {
                fetchItems();
            }
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    useEffect(() => {
        fetchSchema();
        fetchItems();
    }, [selectedCollection, filter, pageIndex, pageSize]);

    const openModal = (item: any) => {
        setEditItem(item);
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setEditItem(null);
    };

    const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setPageSize(Number(e.target.value));
        setPageIndex(0); // Reset to first page when page size changes
    };

    const totalPages = Math.ceil(totalItems / pageSize);

    return (


        <Row>
            <Col xs={24}>
                <Space direction="vertical" Gap>
                    {/*<Title Large>{selectedCollection.charAt(0).toUpperCase() + selectedCollection.slice(1)} Manager</Title>*/}
                    <Divider/>

                    <Input
                        type="text"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        placeholder={`Filter ${selectedCollection}s`}
                    />

                    <Space direction="horizontal" Gap align="center">
                        <span>Page Size:</span>
                        <select onChange={handlePageSizeChange} value={pageSize.toString()}>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                        </select>
                        <input type={"checkbox"} checked={(renderSize === "small")} onClick={() => {
                            if (renderSize === "normal") {
                                setRenderSize("small")
                            } else {
                                setRenderSize("normal")
                            }
                        }}/>

                    </Space>

                    <Divider/>

                    {loading ? (
                        <Paragraph>Loading...</Paragraph>
                    ) : (
                        <>
                            <Row Gap>
                                {items.map((item) => (
                                    <Col xs={24} lg={24} xl={renderSize==="small"?24:12}>
                                        <Space direction="vertical" GapSm key={item.uuid}>

                                            <Space direction="horizontal" GapSm NoWrap>
                                                {renderItem(item, renderSize)}
                                                <Space align={"center"} direction={renderSize==="small"?"horizontal":"vertical"} GapSm NoWrap>
                                                    {actions && actions.map((action) => {
                                                        return <Button size={renderSize} onClick={() => {
                                                            if (action.onClick) action.onClick(item);
                                                        }}>{action.element}</Button>;
                                                    })}
                                                    <Button size={renderSize} onClick={() => {
                                                        navigate("edit/" + item.uuid);
                                                        // openModal(item);
                                                    }}><FontAwesomeIcon icon={faEdit} fixedWidth/></Button>
                                                    <Button type={"danger"} size={renderSize}
                                                            onClick={() => handleDelete(item.uuid)}><FontAwesomeIcon
                                                        icon={faTrashCan} fixedWidth/></Button>
                                                </Space>
                                            </Space>
                                        </Space>
                                    </Col>
                                ))}
                            </Row>

                            <Space direction="horizontal" Gap align="center">
                                <Button onClick={() => setPageIndex(Math.max(0, pageIndex - 1))}
                                        disabled={pageIndex === 0}>
                                    Previous
                                </Button>
                                <span>
                                                    Page {pageIndex + 1} of {totalPages} ({totalItems} items)
                                                </span>
                                <Button onClick={() => setPageIndex(Math.min(totalPages - 1, pageIndex + 1))}
                                        disabled={pageIndex + 1 >= totalPages}>
                                    Next
                                </Button>
                            </Space>
                        </>
                    )}
                </Space>
            </Col>
            {editItem && (
                <Modal
                    visible={isModalVisible}
                    title={`Edit ${selectedCollection.charAt(0).toUpperCase() + selectedCollection.slice(1)}`}
                    content={
                        <form onSubmit={(e) => e.preventDefault()}>
                            <Space Gap>
                                {Object.keys(schema?.properties || {}).map((key) => (
                                    <Input
                                        key={key}
                                        type="text"
                                        value={editItem[key] || ''}
                                        onChange={(e) => setEditItem({...editItem, [key]: e.target.value})}
                                        placeholder={key}
                                    />
                                ))}
                                <Button type="primary">Save</Button>
                                <Button type="default" onClick={closeModal}>Cancel</Button>
                            </Space>
                        </form>
                    }
                    onClose={closeModal}
                />
            )}
        </Row>


    );
};

export default PaginatedCollectionList;
