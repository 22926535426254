import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Divider, Input, Pill, Row, Space, Title} from "../layout/Content";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {faCircleCheck, faCircleXmark} from "@fortawesome/free-regular-svg-icons";
import {useNavigate} from "react-router-dom";

export     const getNickname=(source:any)=>{
    return source.nickname||[source.type, (source.connectionURI||"hostname")+((source.database?(`/${source.database}`):""))].filter(Boolean).join("-")||"data-source-name";
}

// Reusable SelectionCard Component
interface SelectionCardProps {
    title: string;
    description: string;
    isSelected: boolean;
    onClick: () => void;
    details: React.ReactNode[];
    pills?: string[];
    disabled?: boolean;
}

const SelectionCard: React.FC<SelectionCardProps> = ({
                                                         title,
                                                         description,
                                                         isSelected,
                                                         onClick,
                                                         details,
                                                         pills,
                                                         disabled
                                                     }) => (
    <Card Pad Full className={disabled ? "disabled" : (isSelected ? "active" : "")} onClick={disabled ? () => { } : onClick}>
        <Space Gap direction={"vertical"}>
            <Space Gap><input type="checkbox" checked={isSelected} disabled={disabled} />
                <h3>{title}</h3></Space>
            <small>{description}</small>
            <Space GapSm Wide>
                {details.map((detail, idx) => (
                  detail
                ))}
            </Space>
            {pills && (
                <Space GapSm>
                    {pills.map((pill, idx) => (
                        <Pill key={idx}>{pill}</Pill>
                    ))}
                </Space>
            )}
        </Space>
    </Card>
);

// Base class for handling different Data Source types
abstract class DataSourceConfig {
    type: string;
    authType: number;
    connectionURI: string;
    nickname: string;
    description: string;

    constructor(type: string, connectionURI: string, nickname: string, description: string) {
        this.type = type;
        this.connectionURI = connectionURI;
        this.nickname = nickname;
        this.description = description;
        this.authType = 0;
    }

    abstract getPayload(): Record<string, any>;
}

// MariaDB Data Source Configuration
class MariaDBConfig extends DataSourceConfig {
    port: string;
    database: string;
    username: string;
    password: string;

    constructor(connectionURI: string, nickname: string, description: string, port: string, database: string, username: string, password: string, authType:number) {
        super("mariadb", connectionURI, nickname, description);
        this.port = port;
        this.database = database;
        this.username = username;
        this.password = password;
        this.authType = authType;
    }

    getPayload() {
        return {
            type: "mariadb"||this.type,
            connectionURI: this.connectionURI,
            nickname: this.nickname,
            description: this.description,
            port: this.port,
            database: this.database,
            auth: {
                type: this.authType,
                username: this.username,
                password: this.password
            }
        };
    }
}

// MongoDB Data Source Configuration
class MongoDBConfig extends DataSourceConfig {
    authMechanism: string;
    replicaSet: string;

    constructor(connectionURI: string, nickname: string, description: string, authMechanism: string, replicaSet: string) {
        super("mongodb", connectionURI, nickname, description);
        this.authMechanism = authMechanism;
        this.replicaSet = replicaSet;
    }

    getPayload() {
        return {
            type: "mongodb"||this.type,
            connectionURI: this.connectionURI,
            nickname: this.nickname,
            description: this.description,
            authMechanism: this.authMechanism,
            replicaSet: this.replicaSet
        };
    }
}

class WordPressProviderConfig extends DataSourceConfig {

    constructor(connectionURI: string, nickname: string, description: string) {
        super("wordpress", connectionURI, nickname, description);
    }

    getPayload() {
        return {
            type: "wordpress"||this.type,
            connectionURI: this.connectionURI,
            nickname: this.nickname,
            description: this.description,
            auth: {
                type: this.authType
            }
        };
    }
}

const checked = "success muted";

interface DataSourceProps {
    uuid:string;
    type: string;
    connectionURI: string;
    nickname: string;
    description: string;
    authType: number;
    username?: string;
    password?: string;
    port?: string;
    database?: string;
    authMechanism?: string;
    replicaSet?: string;
    integrationType:number;
    dataPrivacyType:number;
    auth:any;
}

interface DatasourceConnectorFormProps {
    datasource?: DataSourceProps;
}

// Main DatasourceConnectorForm Component
const DatasourceConnectorForm: React.FC<DatasourceConnectorFormProps> = ({ datasource }) => {
    const [isEditMode, setIsEditMode] = useState(false);

    const [type, setType] = useState(datasource?.type || 'mariadb');
    const [connectionURI, setConnectionURI] = useState(datasource?.connectionURI || '');
    const [nickname, setNickname] = useState(datasource?.nickname || '');
    const [description, setDescription] = useState(datasource?.description || '');
    const [authType, setAuthType] = useState(datasource?.auth?.type || 0);
    const [username, setUsername] = useState(datasource?.auth?.username || '');
    const [password, setPassword] = useState(datasource?.auth?.password || '');
    const [port, setPort] = useState(datasource?.port || '');
    const [database, setDatabase] = useState(datasource?.database || '');
    const [authMechanism, setAuthMechanism] = useState(datasource?.authMechanism || '');
    const [replicaSet, setReplicaSet] = useState(datasource?.replicaSet || '');
    const [integrationType, setIntegrationType] = useState(datasource?.integrationType || 0);
    const [dataPrivacyType, setDataPrivacyType] = useState(datasource?.dataPrivacyType || 0);

    const navigate = useNavigate();

    // Determine if the form is in edit mode
    useEffect(() => {
        if (datasource) {
            setIsEditMode(true);
        }
    }, [datasource]);

    // Handle form submission
    const handleSubmit = async () => {
        let dataSourceConfig: any;

        // Instantiate appropriate class based on the type
        if (type === 'mariadb') {
            dataSourceConfig = new MariaDBConfig(connectionURI, nickname, description, port, database, username, password, authType);
        } else if (type === 'mongodb') {
            dataSourceConfig = new MongoDBConfig(connectionURI, nickname, description, authMechanism, replicaSet);
        } else if (type === 'wordpress') {
            dataSourceConfig = new WordPressProviderConfig(connectionURI, nickname, description);
        }else{
            console.error("No Data Source Config Selected");
            return;
        }

        const postData = dataSourceConfig?.getPayload();
        postData.integrationType = integrationType;
        postData.dataPrivacyType = dataPrivacyType;

        console.log(isEditMode ? 'Updating datasource' : 'Creating datasource', postData);

        try {
            const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/datasource${(isEditMode&&datasource)?`/${datasource.uuid}`:""}`, {
                method: isEditMode ? 'PUT' : 'POST',
                mode: 'cors',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
            });

            if (!response.ok) {
                throw new Error('Failed to save datasource connector');
            }

            const result = await response.json();
            console.log('DatasourceConnector saved successfully:', result);

            navigate("/databox/sources");

            // Reset form after successful submission
            resetForm();
        } catch (error) {
            console.error('Error saving datasource connector:', error);
        }
    };

    const resetForm = () => {
        setType('mariadb');
        setConnectionURI('');
        setNickname('');
        setDescription('');
        setAuthType(0);
        setUsername('');
        setPassword('');
        setPort('');
        setDatabase('');
        setAuthMechanism('');
        setReplicaSet('');
        setIntegrationType(-1);
        setDataPrivacyType(-1);
    };

    return (
        <Space direction="vertical" Gap>
            <Row Gap>
                <Col xs={24}>
                    <Title Large>{isEditMode ? 'Edit Data Source' : 'Add new Data Source'}</Title>
                </Col>
                <Col xs={24}>
                    <Space Gap>
                        <h2>Connection Details</h2>
                        <Divider />
                    </Space>
                </Col>
                <Col xs={24} md={12}>
                    <Space direction="vertical" Gap>
                        <Space GapSm align="center">
                            <label>Type:</label>
                            <select value={type} onChange={(e) => setType(e.target.value)}>
                                <option value="mariadb">MariaDB</option>
                                <option value="mongodb">MongoDB</option>
                                <option value="wordpress">WordPress</option>
                            </select>
                        </Space>

                        <Space GapSm align="center" direction="horizontal" NoWrap>
                            <Space direction="vertical" Grow>
                                <label>Connection URI:</label>
                                <Input Grow type="text" value={connectionURI}
                                       onChange={(e) => setConnectionURI(e.target.value)}
                                       placeholder={type==="wordpress"?"https://yourblog.com":`${type.toLowerCase()}://abcd.eyz`} />
                            </Space>
                            {type === 'mariadb' && (
                                <Space direction="vertical">
                                    <label>Port:</label>
                                    <input type="text" value={port} onChange={(e) => setPort(e.target.value)} required size={6} />
                                </Space>
                            )}
                        </Space>

                        {type === 'mariadb' && (<Space Wide align={"center"} GapSm>
                            <label>Database:</label>
                            <Input Grow type="text" value={database} onChange={(e) => setDatabase(e.target.value)} placeholder={"appdatabase"} />
                        </Space>)}
                    </Space>
                </Col>

                <Col xs={24} md={12}>
                    <Space direction="vertical" Gap>
                        <Space Wide align={"center"} GapSm>
                            <label>Name:</label>
                            <Input Grow type="text" value={nickname} onChange={(e) => setNickname(e.target.value)} placeholder={getNickname({type, connectionURI, database})} />
                        </Space>
                        <Space Wide align={"center"} GapSm>
                            <label>Description:</label>
                            <Input Grow type="text" value={description} onChange={(e) => setDescription(e.target.value)} placeholder={"details"} />
                        </Space>

                        {type === 'mariadb' && (
                            <>
                                <Space GapSm align="center">
                                    <label>Auth Type:</label>
                                    <select value={authType} onChange={(e) => setAuthType(Number(e.target.value))}>
                                        <option value={0}>None</option>
                                        <option value={1}>Basic Auth</option>
                                    </select>
                                </Space>
                                {authType === 1 && (
                                    <Space GapSm>
                                        <Space direction="vertical" Wide Grow>
                                            <label>Username:</label>
                                            <Input Grow type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="root" />
                                        </Space>
                                        <Space direction="vertical" Wide Grow>
                                            <label>Password:</label>
                                            <Input Grow type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="password" />
                                        </Space>
                                    </Space>
                                )}
                            </>
                        )}

                        {type === 'mongodb' && (
                            <>
                                <Space GapSm align="center">
                                    <label>Auth Mechanism:</label>
                                    <Input type="text" value={authMechanism} onChange={(e) => setAuthMechanism(e.target.value)} placeholder="SCRAM-SHA-256" />
                                </Space>
                                <Space GapSm align="center">
                                    <label>Replica Set:</label>
                                    <Input type="text" value={replicaSet} onChange={(e) => setReplicaSet(e.target.value)} placeholder="rs0" />
                                </Space>
                            </>
                        )}
                    </Space>
                </Col>
            </Row>

            <Row Gap>
                <Col xs={24}>
                    <Space Gap>
                        <h2>Integration Method</h2>
                        <Divider />
                    </Space>
                </Col>

                <Col xs={24} md={12}>
                    <SelectionCard
                        title="Method 1: Schema & Query"
                        description="This approach requires only basic read access to the datasource, focusing primarily on metadata."
                        isSelected={integrationType === 0}
                        onClick={() => setIntegrationType(0)}
                        details={[
                            'Basic read connection',
                            'No table data retrieval, metadata-focused',
                            'Lower accuracy, higher speed, reduced cost',
                            'Outputs: Request & Query structure'
                        ]}
                    />
                </Col>

                <Col xs={24} md={12}>
                    <SelectionCard
                        title="Method 2: RAG & Datastruct"
                        description="Requires full read access to datasource data, enabling data alignment and deeper analysis."
                        isSelected={integrationType === 1}
                        onClick={() => setIntegrationType(1)}
                        details={[
                            'Full read access to datasource',
                            'Includes request, query, and data alignment',
                            'Extensive analysis and optimization insights',
                            'Outputs: Request, Query, Aligned Data, Recommendations'
                        ]}
                    />
                </Col>
            </Row>

            {integrationType !== -1 && (
                <Row Gap>
                    <Col xs={24}>
                        <Space Gap>
                            <h2>Data Privacy Controls</h2>
                            <Divider />
                        </Space>
                    </Col>
                    <Col xs={24} sm={12} xl={6}>
                        <SelectionCard
                            title="None"
                            description="Logs all requests, queries, results, and examples. No restrictions on logging."
                            isSelected={dataPrivacyType === 0}
                            onClick={() => setDataPrivacyType(0)}
                            details={[
                                <Space Wide GapSm justify={"space-between"} align={"center"}>cFX Internal Logs Access<FontAwesomeIcon icon={faCircleCheck} fixedWidth /></Space>,
                                <Space Wide GapSm justify={"space-between"} align={"center"}>cFX Internal Schema Access<FontAwesomeIcon icon={faCircleCheck} fixedWidth /></Space>,
                                <Space Wide GapSm justify={"space-between"} align={"center"}>External Schema Access<FontAwesomeIcon icon={faCircleCheck} fixedWidth /></Space>,
                                <Space Wide GapSm justify={"space-between"} align={"center"}>cFX Internal Data Access<FontAwesomeIcon icon={faCircleCheck} fixedWidth /></Space>,
                                <Space Wide GapSm justify={"space-between"} align={"center"}>External Data Access<FontAwesomeIcon icon={faCircleCheck} fixedWidth /></Space>
                            ]}
                        />
                    </Col>
                    <Col xs={24} sm={12} xl={6}>
                        <SelectionCard
                            title="Limited"
                            description="Logs all requests, queries, and examples, but stored data is kept private."
                            isSelected={dataPrivacyType === 1}
                            onClick={() => setDataPrivacyType(1)}
                            details={[
                                <Space Wide GapSm justify={"space-between"} align={"center"}>cFX Internal Logs Access<FontAwesomeIcon icon={faCircleCheck} fixedWidth /></Space>,
                                <Space Wide GapSm justify={"space-between"} align={"center"}>cFX Internal Schema Access<FontAwesomeIcon icon={faCircleCheck} fixedWidth /></Space>,
                                <Space Wide GapSm justify={"space-between"} align={"center"}>External Schema Access<FontAwesomeIcon icon={faCircleCheck} fixedWidth /></Space>,
                                <Space Wide GapSm justify={"space-between"} align={"center"}>cFX Internal Data Access<FontAwesomeIcon icon={faCircleCheck} fixedWidth /></Space>,
                                <Space Wide GapSm justify={"space-between"} align={"center"}>External Data Access<FontAwesomeIcon className={checked} icon={faCircleXmark} fixedWidth /></Space>
                            ]}
                            pills={['User Facing', 'External Integration']}
                        />
                    </Col>

                    <Col xs={24} sm={12} xl={6}>
                        <SelectionCard
                            title="Lockdown"
                            description="Logs all requests, queries, and examples. Requires approval for exporting logs."
                            isSelected={dataPrivacyType === 2}
                            onClick={() => setDataPrivacyType(2)}
                            details={[
                                <Space Wide GapSm justify={"space-between"} align={"center"}>cFX Internal Logs Access<FontAwesomeIcon icon={faCircleCheck} fixedWidth /></Space>,
                                <Space Wide GapSm justify={"space-between"} align={"center"}>cFX Internal Schema Access<FontAwesomeIcon icon={faCircleCheck} fixedWidth /></Space>,
                                <Space Wide GapSm justify={"space-between"} align={"center"}>External Schema Access<FontAwesomeIcon className={checked} icon={faCircleXmark} fixedWidth /></Space>,
                                <Space Wide GapSm justify={"space-between"} align={"center"}>cFX Internal Data Access<FontAwesomeIcon icon={faCircleCheck} fixedWidth /></Space>,
                                <Space Wide GapSm justify={"space-between"} align={"center"}>External Data Access<FontAwesomeIcon className={checked} icon={faCircleXmark} fixedWidth /></Space>
                            ]}
                            pills={['Finance', 'Operations']}
                        />
                    </Col>

                    <Col xs={24} sm={12} xl={6}>
                        <SelectionCard
                            title="Anonymous"
                            description="Logs all requests, alignment, and examples but without any data or results."
                            isSelected={dataPrivacyType === 3}
                            onClick={() => setDataPrivacyType(3)}
                            details={[
                                <Space Wide GapSm justify={"space-between"} align={"center"}>cFX Internal Logs Access<FontAwesomeIcon icon={faCircleCheck} fixedWidth /></Space>,
                                <Space Wide GapSm justify={"space-between"} align={"center"}>cFX Internal Schema Access<FontAwesomeIcon icon={faCircleCheck} fixedWidth /></Space>,
                                <Space Wide GapSm justify={"space-between"} align={"center"}>External Schema Access<FontAwesomeIcon className={checked} icon={faCircleXmark} fixedWidth /></Space>,
                                <Space Wide GapSm justify={"space-between"} align={"center"}>cFX Internal Data Access<FontAwesomeIcon className={checked} icon={faCircleXmark} fixedWidth /></Space>,
                                <Space Wide GapSm justify={"space-between"} align={"center"}>External Data Access<FontAwesomeIcon className={checked} icon={faCircleXmark} fixedWidth /></Space>
                            ]}
                            pills={['Banking', 'Government']}
                        />
                    </Col>
                </Row>
            )}
            <Row Gap>
                <Col xs={24}>
                    <Button type="primary" onClick={handleSubmit}>{isEditMode ? 'Save Datasource' : 'Create Datasource'}</Button>
                </Col>
            </Row>
        </Space>
    );
};

export default DatasourceConnectorForm;
