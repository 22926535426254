import {
    Col,
    Content,
    Layout,
    Page,
    Row,
    Paragraph,
    Space,
    Title,
    Divider,
    Button, Card, Input, Link, Pill, DropDown
} from "./layout/Content";
import React, {useCallback, useState} from 'react';
import {Footer, Header, Navigation} from "./partial/Navigation";
import {
    faBookBookmark,
    faCircleNodes,
    faCodeBranch, faDatabase,
    faGear,
    faKey,
    faMicrochip, faNetworkWired, faPlus, faSliders,
    faThList, faTurnUp, faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {LoginModal} from "./part/LoginModal";
import {useUserProfileContext} from "../lib/UserContext";
import DatasourceConnectorForm from "./partial/DatasourceConnectorForm";
import {useParams} from "react-router-dom";
import Datasource from "./partial/Datasource";
import Inference from "./partial/Inference";
import {RotateProp} from "@fortawesome/fontawesome-svg-core";
import DataBoxDocs from "./partial/DataBoxDocs";
import MLModel from "./partial/MLModel";
import FineTune from "./partial/FineTune";
import {faPaperPlane} from "@fortawesome/free-regular-svg-icons";
import {
    Announcement,
    Drawer,
    List,
    Modal,
    Result,
    ScrollableContainer,
    ScrollableElement,
    Select,
    Statistic
} from "./layout/Defaults";
import {
    ReactFlow,
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
} from '@xyflow/react';

import '@xyflow/react/dist/style.css';
import {Article} from "./part/Article";


const initialNodes = [
    {id: '1', position: {x: 0, y: 0}, data: {label: '1'}},
    {id: '2', position: {x: 0, y: 100}, data: {label: '2'}},
];

const initialEdges = [{id: 'e1-2', source: '1', target: '2'}];

function Flow() {
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback((params: any) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            fitView
        >
            <Background/>
        </ReactFlow>
    );
}


// MenuItem Component
const MenuItem: React.FC<{
    rotation?: RotateProp,
    icon: any,
    path: string,
    label: string,
    isActiveIcon: boolean,
    isActiveText: boolean,
    disabled?: boolean
}> = ({rotation, icon, path, label, isActiveIcon, isActiveText, disabled}) => {
    return (
        <Space GapSm align={"center"}>
            <FontAwesomeIcon rotation={rotation} fixedWidth icon={icon}
                             className={isActiveIcon ? "active" : "muted-heavy"}/>
            {disabled ? <Paragraph><strong>{label}</strong></Paragraph> :
                <Link className={isActiveText ? "active" : ""} href={path}>{label}</Link>}
        </Space>
    );
};

export const Menu: React.FC<{ button?: boolean }> = ({button}) => {
    const {getUserProfile} = useUserProfileContext();

    const isPathActive = (basePath: string) => window.location.pathname === basePath;
    const isSubpathActive = (basePath: string) => window.location.pathname.startsWith(basePath) && window.location.pathname !== basePath;

    const contents = () => {
        return (
            <Space justify="space-between" direction="vertical" GapSm Wide>
                <Space direction="vertical" GapSm>
                    <MenuItem icon={faNetworkWired} path="/databox" label="Inference & API"
                              isActiveIcon={isPathActive("/databox")} isActiveText={isPathActive("/databox")}/>
                    <Divider/>

                    <h4 className="muted">DataBox Tools</h4>
                    {/* Data Sources should only activate the icon on subpages */}
                    <MenuItem
                        icon={faDatabase}
                        path="/databox/sources"
                        label="Data Sources"
                        isActiveIcon={isPathActive("/databox/sources") || isSubpathActive("/databox/sources")}
                        isActiveText={isPathActive("/databox/sources")}
                    />

                    {isPathActive("/databox/sources/add") && (
                        <MenuItem
                            icon={faTurnUp}
                            rotation={90}
                            path="/databox/sources/add"
                            label="Add New Source"
                            isActiveIcon={isPathActive("/databox/sources/add")}
                            isActiveText={isPathActive("/databox/sources/add")}
                        />
                    )}
                    {isSubpathActive("/databox/sources/edit") && (
                        <MenuItem
                            icon={faTurnUp}
                            rotation={90}
                            path="/databox/sources/edit"
                            label="Edit Data Source"
                            disabled={true}
                            isActiveIcon={isSubpathActive("/databox/sources/edit")}
                            isActiveText={isSubpathActive("/databox/sources/edit")}
                        />
                    )}

                    <MenuItem icon={faMicrochip} path="/databox/models" label="Models"
                              isActiveIcon={isPathActive("/databox/models") || isSubpathActive("/databox/models")}
                              isActiveText={isPathActive("/databox/models")}
                    />
                    {isSubpathActive("/databox/models") && (
                        <MenuItem
                            icon={faTurnUp}
                            rotation={90}
                            path="/databox/models/add"
                            label="Add New Model"
                            isActiveIcon={isPathActive("/databox/models/add")}
                            isActiveText={isPathActive("/databox/models/add")}
                        />
                    )}

                    <MenuItem
                        icon={faSliders}
                        path="/databox/tuning"
                        label="Fine-tuning"
                        isActiveIcon={isPathActive("/databox/tuning") || isSubpathActive("/databox/tuning")}
                        isActiveText={isPathActive("/databox/tuning")}
                    />

                    {isPathActive("/databox/tuning/add") && (
                        <MenuItem
                            icon={faTurnUp}
                            rotation={90}
                            path="/databox/tuning/add"
                            label="Add New Fine-tuning"
                            isActiveIcon={isPathActive("/databox/tuning/add")}
                            isActiveText={isPathActive("/databox/tuning/add")}
                        />
                    )}
                    {isSubpathActive("/databox/tuning/edit") && (
                        <MenuItem
                            icon={faTurnUp}
                            rotation={90}
                            path="/databox/tuning/edit"
                            label="Edit Fine-tuning"
                            disabled={true}
                            isActiveIcon={isSubpathActive("/databox/tuning/edit")}
                            isActiveText={isSubpathActive("/databox/tuning/edit")}
                        />
                    )}


                    {(isPathActive(("/databox/tuning")) || isSubpathActive("/databox/tuning")) && (
                        <MenuItem
                            icon={faPaperPlane}
                            path="/databox/tuning/prompt"
                            label="Prompts"
                            isActiveIcon={isPathActive("/databox/tuning/prompt")}
                            isActiveText={isPathActive("/databox/tuning/prompt")}
                        />
                    )}

                    <MenuItem icon={faBookBookmark} path="/databox/docs" label="Documentation"
                              isActiveIcon={isPathActive("/databox/docs")}
                              isActiveText={isPathActive("/databox/docs")}/>

                    <Divider/>

                    <h4 className="muted">ClusterFX Services</h4>
                    <MenuItem icon={faCircleNodes} path="/services" label="Overview"
                              isActiveIcon={isPathActive("/services")} isActiveText={isPathActive("/services")}/>
                    <MenuItem icon={faCodeBranch} path="/databox/explorer" label="Explorer"
                              isActiveIcon={isPathActive("/databox/explorer")}
                              isActiveText={isPathActive("/databox/explorer")}/>
                    <MenuItem icon={faThList} path="/logs" label="Logs" isActiveIcon={isPathActive("/logs")}
                              isActiveText={isPathActive("/logs")}/>
                    <MenuItem icon={faKey} path="/keys" label="API Keys" isActiveIcon={isPathActive("/keys")}
                              isActiveText={isPathActive("/keys")}/>

                    <Divider/>

                    <h4 className="muted">Management</h4>
                    <MenuItem icon={faGear} path="/settings" label="Account & Settings"
                              isActiveIcon={isPathActive("/settings")} isActiveText={isPathActive("/settings")}/>
                </Space>

                <Space direction="vertical" justify="end" GapSm className="wide">
                    {!getUserProfile() && (
                        <LoginModal defaultMode={true}>
                            <Button type="primary" className="wide">
                                <FontAwesomeIcon fixedWidth icon={faUserPlus}/>
                                <span className="hide-sm"> Sign up or Log in</span>
                            </Button>
                        </LoginModal>
                    )}
                </Space>
            </Space>
        );
    }

    if (button) {
        return <DropDown className={"show-md"}>{contents()}</DropDown>

    }

    return <Content Pad style={{position:"sticky",top:"0"}}>
        {contents()}
    </Content>
};


const Generated: React.FC = () => {

    const {page} = useParams<{ page: string }>();


    return (
        <Layout>
            <Header/>
            <Page Grow className="app-main">
                <Content className={"limits wide full"}>
                    <Row className={"full"}>
                        <Col xs={24} md={8} lg={6} xl={4} style={{maxHeight: "initial", background: "#fcfcfc"}}
                             className={"hide-md"}>
                            <Menu/>
                        </Col>

                        <Col xs={24} md={16} lg={18} xl={20} style={{maxHeight: "initial"}}>
                            <Row>
                                <Col xs={24} xl={18} style={{maxHeight: "initial"}}>


                                    <Space justify="space-between" direction="vertical" GapSm Full>
                                        <Space direction="vertical" GapSm Full>


                                            <Article/>


                                        </Space>
                                    </Space>


                                </Col>
                                <Col xs={24} xl={6} style={{maxHeight: "initial"}}>
                                    <Space justify="space-between" direction="vertical" GapSm Full>
                                        <Content className={"pad comment"} style={{position:"sticky",top:"0"}}>

                                            <Space direction="vertical" GapSm Full Wide>


                                                This is the metadata section / XL sidebar

                                                <Paragraph>ClusterFX can do it all..!</Paragraph>

                                                <Button>Get your Free API Key</Button>
                                                <Button type={"primary"}>Start a Project Consultation</Button>


                                            </Space>
                                        </Content>
                                    </Space>
                                </Col>


                            </Row>
                        </Col>


                    </Row>
                </Content>
            </Page>
            <Footer/>
        </Layout>
    );
}

export default Generated;
