import {
    Col,
    Content,
    Layout,
    Page,
    Row,
    Paragraph,
    Space,
    Title,
    Divider,
    Button, Card, Input, Link, Pill
} from "./layout/Content";
import React from 'react';
import {Footer, Header, Navigation} from "./partial/Navigation";

const LandingPage: React.FC = () => {
    return (
        <Layout>
            <Header/>
            <Page Grow className="app-main">
                <Content className={"limits pad"}>
                    <Row Gap>


                        <Col xs={24} md={12}>
                            <Content>
                                <Space GapSm direction={"vertical"} className={"huge muted"}>

                                    <Title Large>Private RAG & LLM Inference</Title>
                                    <Title Large>Natural Language Analytics</Title>
                                    <Title Large>Whitelabel Integration</Title>
                                    <Divider/>
                                    <Paragraph>We offer many AI driven API Endpoints incuding:</Paragraph>
                                    <ul>
                                        <li>BLiP Image Description</li>
                                        <li>LLM Text Generation & Chat</li>
                                        <li>Private Inference & Fine-Tuning LLM Platform</li>
                                        <li>Multi-Tenant Analytics</li>
                                        <li>Dataset Generation & Filtering</li>
                                    </ul>
                                    <p>
                                        All features are accessible via our intuitive online interface and API
                                        endpoints. Take
                                        your business to the next level with scalable, AI-powered solutions.
                                    </p>
                                </Space>
                            </Content>
                        </Col>

                        <Col xs={24} md={12} className="hero">
                            <Space direction="vertical" Full align={"center"} justify={"center"}>
                                <Content Pad>
                                    <Space direction={"vertical"} Fill Gap className={"huge"}>
                                        <Title Large className={"huge"}>AI Powered Insights</Title>
                                        <Paragraph style={{color: "white"}}>Relation Database Integration, Multi-tenant
                                            Analytics, Secure Private Integrations, Automatic Database Schema & DDL
                                            Mappings, Recommendations & Insights, and much more.</Paragraph>
                                        <Button href={"/databox"} type={"default"}>Start using DataBox Toolkit</Button>
                                    </Space>
                                </Content>
                            </Space>
                        </Col>


                        <Col xs={24} md={8}>
                            <Space justify="space-between" direction="vertical" GapSm Full>
                                <Space direction="vertical" GapSm>
                                    <Title>DataBox Toolkit</Title>
                                    <Paragraph>
                                        Run private large language models with ease. Our platform allows for secure
                                        fine-tuning and inference, helping you maintain control over your data and
                                        models. Import multiple datasets and manage everything through our online
                                        dashboard.
                                    </Paragraph>
                                </Space>
                                <Space direction="vertical" justify="end">
                                    <Space GapSm><Pill>Secure</Pill><Pill>Scalable</Pill></Space>
                                </Space>
                            </Space>
                        </Col>
                        <Col xs={24} md={8}>
                            <Space justify="space-between" direction="vertical" GapSm Full>
                                <Space direction="vertical" GapSm>
                                    <h1>API Endpoints</h1>
                                    <Paragraph>
                                        Get familiar with our multitude of API Endpoints available at all scales.
                                    </Paragraph>
                                </Space>
                                <Space direction="vertical" justify="end">
                                    <Space GapSm><Pill>Accurate</Pill><Pill>Efficient</Pill></Space>
                                </Space>
                            </Space>
                        </Col>

                        <Col xs={24} md={8}>
                            <Space justify="space-between" direction="vertical" GapSm Full>
                                <Space direction="vertical" GapSm>
                                    <h1>Dataset Filtering & Generation</h1>
                                    <Paragraph>
                                        Quickly generate and filter datasets tailored to your needs. Our tools allow you
                                        to fine-tune your models with high-quality datasets, ensuring optimal
                                        performance
                                        for any task.
                                    </Paragraph>
                                </Space>
                                <Space direction="vertical" justify="end">
                                    <Space GapSm><Pill>Customizable</Pill><Pill>Powerful</Pill></Space>
                                </Space>
                            </Space>
                        </Col>

                        <Col xs={24} className={"hero alt"}>
                            <Content Pad>
                                <Space id="our-benefits" direction={"vertical"} align={"center"} Gap>
                                    <Title Large>Customizable Trial and API usage up to <Pill>$500 free!</Pill></Title>
                                    <Button type={"primary"}>Get Started with a Free API Key</Button>
                                </Space>
                            </Content>
                        </Col>

                        <Col xs={24} md={24}>
                            <Space Gap id="additional-features">
                                <h3>Method 3: Extended Features (Available for Both Methods)</h3>
                                <p>
                                    Regardless of the chosen integration method, a variety of additional features can be
                                    enabled to
                                    enhance API interactions. These features are designed to provide more extensive
                                    insights and
                                    debugging capabilities, ensuring that your AI-powered data solutions are efficient
                                    and scalable.
                                </p>
                                <ul>
                                    <li>Request Restructuring</li>
                                    <li>Extensive Data Debugging Outputs</li>
                                    <li>Multipart Outputs: Text/Query, Charts, Tables, Data Structures</li>
                                    <li>Custom Data Alignment Configurations</li>
                                    <li>Advanced Model Recommendations</li>
                                    <li>Private Multi-Tenant AI Analytics</li>
                                    <li>Usage Tracking with API Key-based Logging</li>
                                </ul>
                            </Space>
                        </Col>

                        <Col xs={24} className={"hero altb"}>
                            <Content Pad>
                                <Space id="location" direction={"vertical"} GapSm>
                                    <h2>Global Reach from Dallas</h2>
                                    <p>Based in Dallas, Texas, <strong>ClusterFX</strong> serves businesses worldwide.
                                        We are ready to help you harness AI technologies to drive innovation and
                                        transformation.
                                    </p>
                                </Space>
                            </Content>
                        </Col>
                    </Row>
                </Content>
            </Page>
            <Footer/>
        </Layout>
    );
}

export default LandingPage;
