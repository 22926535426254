import React, { useEffect, useRef, useState } from 'react';
import { getNickname } from './DatasourceConnectorForm';
import {Button, Card, Col, Content, Input, Paragraph, Pill, Row, Space} from '../layout/Content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';
// @ts-ignore
import MarkdownRenderer from 'react-markdown-renderer';
import {Empty, Select, Tooltip} from '../layout/Defaults';
import SyntaxHighlighter from "react-syntax-highlighter";
import {github, githubGist, xcode} from "react-syntax-highlighter/dist/esm/styles/hljs";
import {
    faFlask,
    faRobot, faSpinner,
    faTable,
    faTableCellsRowUnlock,
    faTriangleExclamation, faUsers
} from "@fortawesome/free-solid-svg-icons";
import Badge from "../part/Badge";
import DataCard, { StatsDetails } from "./DataCard";
import {useUserProfileContext} from "../../lib/UserContext";

export const renderResponse = (data: any) => {
    const { alignment } = data || {};

    if (!alignment || !alignment.intent) {
        return 'Invalid data received.';
    }

    if (alignment.intent === "question"){
        return data.content;
    }

 //   return <span>{JSON.stringify(data.response)}</span>

    let result = data.results;
    if (Array.isArray(result)){
        result = data.results[data.results.length-1];
    }



    const intent = () => {
        if (data.response){
            try {
                const details = JSON.parse(data.response);

                return <Space GapSm direction={"vertical"}>
                    <MarkdownRenderer markdown={(details.explanation||result?.content||data.content||"")} options={{preset:"full"}} />
                    <DataCard incoming={data} response={details}/>
                </Space>
            }catch (e) {
                console.log(e);
            }
        }

        switch (alignment.intent) {
            case 'question':
                return data.content;
            case 'answer':
                return `Answer: ${JSON.stringify(data.response)}`;
            case 'table':
                return renderTable(data.data);
            case 'list':
                return renderList(data.data);
            case 'graph':
                return 'Graph rendering is not implemented yet.';
            default:
                return `Unknown intent: ${alignment.intent} ${JSON.stringify(data.content||result)}`;
        }
    };
// <span>{intent()}</span>
    return (
        <Space Gap direction={"vertical"}>
            <div className={"markdown-viewer"} style={{flex: "1 0 auto"}}>{intent()}</div>
            {data.stats&&<small><StatsDetails stats={data.stats}/></small>}
        </Space>
    );
};

const getCurl = (sources:any[], selectedSource:string) =>{
    return `# clusterfx.org inference ${getNickname(sources?.filter((s)=>{return s.uuid === selectedSource})[0])}\ncurl -X POST ​https://api.clusterfx.org/​actions/​datasource/​inference/​${selectedSource} \n-H "Authorization: Bearer a01b02-your-api-key-c03d05" \n-d '{{"prompt": "${prompt}","options":{"alignment":true}}}'`;
}

const renderTable = (results: any[]) => {
    if (results.length === 0) return 'No results to display.';
    const headers = Object.keys(results[0]);

    return (
        <table>
            <thead>
            <tr>
                {headers.map((header) => (
                    <th key={header}>{header}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            {results.map((row, rowIndex) => (
                <tr key={rowIndex}>
                    {headers.map((header) => (
                        <td key={`${rowIndex}-${header}`}>{row[header]}</td>
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
    );
};



interface Message {
    sender: string;
    content: {
        alignment: {
            intent: string;
        };
        [key: string]: any; // Additional properties for content
    };
}

interface MessageCardProps {
    message: Message;
}

const MessageCard: React.FC<MessageCardProps> = ({ message }) => {
    // Create a copy of the message to safely modify its content
    const [modifiedMessage, setModifiedMessage] = useState({ ...message });

    const handleIntentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newIntent = e.target.value;
        // Update the intent in the copy of the message
        setModifiedMessage(prevState => ({
            ...prevState,
            content: {
                ...prevState.content,
                    intent: newIntent,
            }
        }));
    };

    return (
        <div className="card pad">
            <Space GapSm direction={"vertical"}>
                <Space Wide GapSm justify={"space-between"}>
                    <strong>{modifiedMessage.sender}<span className={"muted-heavy"}>:</span></strong>
                    <select value={modifiedMessage.content.intent||"answer"} onChange={handleIntentChange}>
                        <option value="answer">Answer</option>
                        <option value="suggestion">Suggestion</option>
                        <option value="series">Series</option>
                        <option value="list">List</option>
                        <option value="table">Table</option>
                        <option value="chart">Chart</option>
                        <option value="longform">Longform</option>
                        <option value="reference">Reference</option>
                    </select>
                </Space>
                <div>{modifiedMessage.content && renderResponse(modifiedMessage.content)}</div>
            </Space>
        </div>
    );
};


const renderList = (results: any[]) => (
    <ul>
        {results.map((item, index) => (
            <li key={index}>{JSON.stringify(item)}</li>
        ))}
    </ul>
);

const Inference: React.FC = () => {

    const {queryUserProfile, getUserProfile, hasQueriedUserProfile} = useUserProfileContext();

    const [prompt, setPrompt] = useState<string>('');
    const [messages, setMessages] = useState<Array<{ sender: string, content: any }>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [sources, setSources] = useState<any[] | null>(null);
    const [selectedSource, setSelectedSource] = useState<string | null>(null);
    const [models, setModels] = useState<any[] | null>(null);
    const [selectedModel, setSelectedModel] = useState<string | null>(null);
    const [selectedModerationModel, setSelectedModerationModel] = useState<string | null>(null);

    const messagesEndRef = useRef<HTMLDivElement | null>(null);

    const getSource = (source:string)=>{
        if (!sources || !source){
            return false;
        }
        return sources?.find((a:any)=>{return a.uuid===source});
    }

    const getModel = (source:string)=>{
        if (!models || !source){
            return false;
        }
        return models?.find((a:any)=>{return a.uuid===source});
    }


    const isDataPrivacyError = (model?:string)=>{
        if (!selectedSource || !model){
            return false;
        }
       // if (!model && models){
        //    return models.map((model)=>{ return (model.is_external && selectedSource && getSource(selectedSource)?.dataPrivacyType>=2 ) }).filter((a:boolean)=>{return a===true}).length > 0;
       // }
        return (getModel(model).is_external && selectedSource && getSource(selectedSource)?.dataPrivacyType>=2 )
    }

    useEffect(() => {
        const fetchDataSources = async () => {

            try {
                const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/datasource`, {
                    method: 'GET',
                    mode: 'cors',
                    credentials: 'include',
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data: any = await response.json();
                setSources(data.results);
                setSelectedSource(data.results?.[0]?.uuid || null); // Set the first source as default
            } catch (err) {
                console.error(err);
            }

        };
        const fetchModels = async () => {

            try {
                const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/model`, {
                    method: 'GET',
                    mode: 'cors',
                    credentials: 'include',
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data: any = await response.json();
                setModels(data.results);
                setSelectedModel(data.results?.[0]?.uuid || null); // Set the first source as default
            } catch (err) {
                console.error(err);
            }

        };
        fetchDataSources();
        fetchModels();
    }, []);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleSend = async () => {
        if (!prompt.trim() || !selectedSource) {
            return;
        }

        setMessages((prevMessages) => [...prevMessages, { sender: 'User', content: {content:prompt, alignment:{intent:"question"}} }]);

        const p = prompt+"";
        setPrompt(''); // Clear the input


        try {
            setLoading(true);
            const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/actions/datasource/inference/${selectedSource}`, {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}` // Add your API key here
                },
                body: JSON.stringify({ prompt:p, model:selectedModel, moderation:selectedModerationModel })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            // @ts-ignore
            setMessages((prevMessages) => [...prevMessages, { sender: 'Assistant', content: (data) }]);

        } catch (error: any) {
            console.error('Error fetching data:', error.message);
            setMessages((prevMessages) => [...prevMessages, { sender: 'Assistant', content: false }]);
        } finally {
            setLoading(false);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSend();
        }
    };

    const user = getUserProfile();

    return (

        <Space Gap direction={"vertical"} Full NoWrap>
            <h1>Chatbot Interface</h1>
            <Space Wide GapSm direction={"vertical"}>

               <Space GapSm align={"end"}>
                   <Space direction={"vertical"} GapSm> <strong>Data Source</strong>{sources && (
                    <Select
                        defaultValue={sources[0]?.uuid||""}
                        options={(!sources||sources.length===0)?[{
                            value: undefined,
                            element: (
                                <Space className={"disabled"} align="center" GapSm NoWrap>
                                    <FontAwesomeIcon className={"warning"} icon={faTriangleExclamation} fixedWidth={true} />
                                    <span>No Sources Available</span>
                                </Space>
                            )
                        }]:sources.map((source) => ({
                            value: source.uuid,
                            element: (
                                <Space align="center" GapSm NoWrap>
                                    <FontAwesomeIcon icon={faTable} fixedWidth={true} />
                                    {(!user || (user && user.uuid !== source.owner))&&<Tooltip message={"Public / Community Data Source"}><FontAwesomeIcon icon={faUsers} fixedWidth={true} /></Tooltip>}
                                    <span>{source.nickname || getNickname(source) || "Unknown Source"}</span>
                                </Space>
                            )
                        }))}
                        onSelected={(value: string) => setSelectedSource(value)}
                    />
                   )}</Space>
                <Input
                    Grow
                    type="text"
                    value={prompt}
                    disabled={loading}
                    onChange={(e) => setPrompt(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Type your message here..."
                />
                <Button
                    onClick={handleSend}
                    type={(loading||isDataPrivacyError(selectedModel||""))?"disabled":"primary"}
                >
                    Send
                </Button>
               </Space>

               <Space GapSm align={"stretch"}>

                   {(models&&models.length>0)&&<Space direction={"vertical"} GapSm> <strong>Moderation Model</strong>{models && (
                    <Space><Select
                        type={isDataPrivacyError(selectedModerationModel||"")?"danger":undefined}
                        categorize={{
                            property: "model_task",
                            order: ["moderation", "instruct", "completion", "alignment", "results"]
                        }}
                        defaultValue={models[0].uuid}
                        options={models.map((model) => ({
                            category:model.model_task,
                            value: model.uuid,
                            element: (
                                <Space align="center" GapSm>
                                    {(!user || (user && user.uuid !== model.owner))&&<Tooltip message={"Public / Community Model"}><FontAwesomeIcon icon={faUsers} fixedWidth={true} /></Tooltip>}
                                    {isDataPrivacyError(model.uuid)&&<Tooltip message={"External Model cannot be used with Data Source Privacy Setting"}><FontAwesomeIcon icon={faTriangleExclamation} fixedWidth={true} /></Tooltip>}
                                    {model.name || "Unknown Model"}
                                </Space>
                            )
                        }))}
                        onSelected={(value: string) => setSelectedModerationModel(value)}
                    /></Space>
                )}</Space>}

                   {(models&&models.length>0)&& <Space direction={"vertical"} GapSm> <strong>Inference Model</strong>{models && (
                    <Select
                        type={isDataPrivacyError(selectedModel||"")?"danger":undefined}
                        defaultValue={models[0].uuid}
                        options={models.map((model) => ({
                            value: model.uuid,
                            element: (
                                <Space align="center" GapSm>
                                    {(!user || (user && user.uuid !== model.owner))&&<Tooltip message={"Public / Community Model"}><FontAwesomeIcon icon={faUsers} fixedWidth={true} /></Tooltip>}
                                    {isDataPrivacyError(model.uuid)&&<Tooltip message={"External Model cannot be used with Data Source Privacy Setting"}><FontAwesomeIcon icon={faTriangleExclamation} fixedWidth={true} /></Tooltip>}
                                    {model.name || "Unknown Model"}
                                </Space>
                            )
                        }))}
                        onSelected={(value: string) => setSelectedModel(value)}
                    />
                )}</Space>}

               </Space>
            </Space>

            <Space Grow style={{ border: '1px solid #ccc', padding: '10px', minHeight:"300px", overflowY: 'scroll' }}>
                <Content>
                {(!messages||messages.length==0)&&<Empty description={"Start with a Query about your Data"}/>}
                <Space GapSm direction={"vertical"}>
                    {messages.map((message, index) => (
                        <MessageCard message={message} />
                    ))}
                    {loading && <Card Pad><Space align={"center"} justify={"center"}><Space GapSm align={"center"}><FontAwesomeIcon icon={faSpinner} spinPulse /><em className={"muted"}>Processing</em></Space></Space></Card>}
                    <div ref={messagesEndRef} />
                </Space>
                </Content>
            </Space>
        </Space>
    );
};

export default Inference;
