import React from 'react';
import {Card, Divider, Space, Title} from "../layout/Content";
import {ResponsiveLine} from "@nivo/line";

interface FieldMapping {
    column_name: string;
    render_type: 'primary' | 'value' | 'attribute' | 'timestamp' | 'composite';
    label: string;
    value_unit: string;
    description: string;
    composite_fields: string[];
    time_series_format: {
        time_column: string;
        value_columns: string[];
        granularity: string;
    };
}
interface ChartComponentProps {
    data: any[];
    response: DataCardResponse;
}

const ChartComponent: React.FC<ChartComponentProps> = ({ data, response }) => {

    const { time_series_format } = response;
    if (!time_series_format || !time_series_format.time_column || !time_series_format.value_columns) {
        console.log('No details', time_series_format);
        return null;
    }

    const timeColumn = time_series_format.time_column;
    const valueColumns = time_series_format.value_columns;

    // Prepare the data for Nivo Line chart
    const series = valueColumns.map((valueColumn) => ({
        id: valueColumn,
        data: data.map((item: any) => ({
            x: new Date(item[timeColumn]).toISOString(), // Nivo expects ISO date strings for the x-axis
            y: parseFloat(item[valueColumn])
        }))
    }));

    return (
        <div style={{ height: '400px' }}>
            <ResponsiveLine
                data={series}
                margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                xScale={{ type: 'time', format: "%Y-%m-%dT%H:%M:%S.%L%Z", precision: 'day' }}
                xFormat="time:%Y-%m-%d"
                yScale={{ type: 'linear', stacked: false, min: 'auto', max: 'auto' }}
                axisLeft={{
                    legend: 'Value',
                    legendOffset: -40,
                    legendPosition: 'middle',
                }}
                axisBottom={{
                    format: '%b %d',
                    legend: 'Time',
                    legendOffset: -12,
                    legendPosition: 'middle',
                }}
                pointSize={10}
                pointBorderWidth={2}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
            />
        </div>
    );
};



interface DataCardResponse {

        content: any;
        intent: string;
        field_mappings: FieldMapping[];
        time_series_format: {
            time_column: string,
            value_columns: string[],
            granularity: string
        }

}

interface DataCardProps {
    incoming: any;
    response: DataCardResponse
}

const DataCard: React.FC<DataCardProps> = ({incoming, response}) => {
    const fields = response.field_mappings || [];
    const data:any[] = incoming.data[0];


    console.log("response", response, data);
    // Function to render an SVG chart for time series data


    console.log("incoming", incoming);

    if (incoming.intent && incoming.intent === "series") {
        return (
            <div>
                <h3>{incoming.intent}</h3>
                {data&&<ChartComponent data={data} response={response} />}
            </div>
        );
    }

    return (
        <Space Gap>

            {data.map((item: any, rowIndex: number) => {
                const fieldMappings = fields.map(field => ({
                    ...field,
                    value: item[field.column_name] || '',
                }));

                // Get the primary type item for the card title
                const primaryItem = fieldMappings.find(fm => fm.render_type === 'primary');

                return (
                    <Card Pad key={rowIndex}>
                        <Space direction="vertical">
                            {primaryItem ? (
                                <span>
                                    {primaryItem.label}:
                                    <Title Large>
                                        {primaryItem.value}
                                    </Title>
                                </span>
                            ) : (
                                'Unknown Title'
                            )}
                            {primaryItem && primaryItem.description && <small>{primaryItem.description}</small>}

                            {/* Render value entries */}
                            {fieldMappings
                                .filter(fm => fm.render_type === 'value')
                                .map((fm, valueIndex) => (
                                    <Space key={valueIndex} justify="space-between">
                                        <span><strong>{fm.label}:</strong></span>
                                        <span>
                                            {fm.value} {fm.value_unit}
                                        </span>
                                    </Space>
                                ))}

                            {/* Render attributes */}
                            <Divider/>
                            {fieldMappings
                                .filter(fm => fm.render_type === 'attribute')
                                .map((fm, attributeIndex) => (
                                    <div key={attributeIndex}>
                                        <strong>{fm.label}:</strong> {fm.value} {fm.value_unit}
                                    </div>
                                ))}

                            {/* Render timestamp */}
                            {fieldMappings
                                .filter(fm => fm.render_type === 'timestamp')
                                .map((fm, timestampIndex) => (
                                    <div key={timestampIndex}>
                                        <strong>{fm.label}:</strong> {new Date(fm.value).toLocaleDateString()} {fm.value_unit}
                                    </div>
                                ))}

                            {/* Render composite fields */}
                            {fieldMappings
                                .filter(fm => fm.render_type === 'composite')
                                .map((fm, compositeIndex) => (
                                    <div key={compositeIndex}>
                                        <strong>{fm.label}:</strong> {fm.composite_fields.map(cf => item[cf]).join(', ')} {fm.value_unit}
                                    </div>
                                ))}
                        </Space>
                    </Card>
                );
            })}
        </Space>
    );
};


export const StatsDetails: React.FC<{ stats: any }> = ({stats}) => {

    return (<Card className={"muted"}><Space direction={"vertical"}>
        <Space GapSm><strong>Inference Timing</strong>
            <span>{(stats?.timing?.moderation || 0).toFixed(4)}ms Moderation</span>
            <span>{(stats?.timing?.alignment || 0).toFixed(4)}ms Alignment</span>
            <span>{(stats?.timing?.inference || 0).toFixed(4)}ms Inference</span>
            <span>{(stats?.timing?.query || 0).toFixed(4)}ms Query</span>
            <span>{(stats?.timing?.results || 0).toFixed(4)}ms Results</span>
        </Space>
        <Space GapSm><strong>Token Usage</strong> <span>{stats?.usage?.input_tokens} Input</span>
            <span>{stats?.usage?.output_tokens} Output</span> </Space>
    </Space></Card>);

}

export default DataCard;
