import React from 'react';
//import 'oakd/src/Reset.scss';
import './App.scss';
import LandingPage from "./component/Landing";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {PageComponent} from "./component/PageComponent";
import {StyleGuide} from "./component/page/StyleGuide";
import {getPlatform, getWrapper} from "./lib/Wrapper";
import DataBox from './component/DataBox';
import {PrivacyPolicy} from "./component/page/PrivacyPolicy";
import {TermsOfService} from "./component/page/TermsOfService";
import {CookiePolicy} from "./component/page/CookiePolicy";
import UserProfileProvider from "./lib/UserContext";
import Logs from "./component/partial/Logs";
import Generated from "./component/Generated";

function App() {
    return (
        <div className={["App", getPlatform(), getWrapper()].join(" ")}>
            <UserProfileProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/new" element={<PageComponent element={<Generated/>}/>}/>
                        <Route path="/" element={<PageComponent element={<LandingPage/>}/>}/>
                        <Route path="/style" element={<PageComponent element={<StyleGuide/>}/>}/>

                        <Route path="/databox" element={<PageComponent element={<DataBox/>}/>}/>
                        <Route path="/databox/:page" element={<PageComponent element={<DataBox/>}/>}/>
                        <Route path="/databox/:page/:controller" element={<PageComponent element={<DataBox/>}/>}/>
                        <Route path="/databox/:page/:controller/:uuid" element={<PageComponent element={<DataBox/>}/>}/>

                        <Route path="/logs" element={<PageComponent element={<Logs/>}/>}/>


                        <Route path="/info/privacy-policy" element={<PageComponent element={<PrivacyPolicy/>}/>}/>
                        <Route path="/info/terms-of-service" element={<PageComponent element={<TermsOfService/>}/>}/>
                        <Route path="/info/cookie-policy" element={<PageComponent element={<CookiePolicy/>}/>}/>
                    </Routes>
                </BrowserRouter>
            </UserProfileProvider>
        </div>
    );
}

export default App;
