import {javascript} from '@codemirror/lang-javascript';
import {sql} from '@codemirror/lang-sql';
import {yaml} from '@codemirror/lang-yaml';
import {python} from '@codemirror/lang-python';
import CodeMirror from '@uiw/react-codemirror';
import {EditorView} from "@codemirror/view";
import { dockerFile } from '@codemirror/legacy-modes/mode/dockerfile'
import React from "react";


function normalizeLineEndings(str: string) {
    if (!str) return str;
    return str.replace(/\r\n|\r/g, '\n');
}

const CM: React.FC<any> = ({
                               raw,
                               name,
                               value,
                               onChange,
                               readOnly,
                               height = "auto",
                               theme,
                               style = {},
                               language
                           }) => {

    const editorClassName = "ReactCodeMirror";

    const onHandleChange = (e: any) => {
        //  console.log("CM", e);
        onChange({target: {value: e}});
    }

    const getSyntaxHighlighter = () : any => {
        switch (language) {
            case "python":
                console.log("PYHTONG");
                return [python(),EditorView.lineWrapping];
            case "jsx":
                return [EditorView.lineWrapping,javascript({ jsx: true })];
            case "sql":
                return [EditorView.lineWrapping,sql()];
            case "yaml":
                return [EditorView.lineWrapping,yaml()];
        }
        return [EditorView.lineWrapping];
    };



    return (
        <CodeMirror
            value={raw || ""}
            height={height}
            //width="100%"
            style={{...style, ...{fontSize: "9pt"}}}
            theme={theme || undefined}
            readOnly={readOnly}
            extensions={getSyntaxHighlighter()}
            onChange={onHandleChange}
        />
    );
}

export default CM;
