import React, {useState} from "react";
import CM from "../partial/CodeMirror";
import {Button, Card, Col, Content, Divider, Link, Paragraph, Pill, Row, Small, Space, Title} from "../layout/Content";
import {Announcement, BlockWarning, Collapsible, Drawer, Modal, Result, Tabs} from "../layout/Defaults";
import FileSystemTree from "./FileSystemTree";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBullhorn, faChartLine, faCode, faCogs, faTruckLoading, faUsersCog} from "@fortawesome/free-solid-svg-icons";
import {faPython} from "@fortawesome/free-brands-svg-icons";
import Badge from "./Badge";
import {faComments} from "@fortawesome/free-regular-svg-icons";

export const Article: React.FC = () => {
    const textClassifier = `import transformers
from flask import Flask, request, jsonify

app = Flask(__name__)

# Load the text classification model
classifier = transformers.pipeline('text-classification')

@app.route('/classify', methods=['POST'])
def classify_text():
    data = request.json
    text = data.get('text')
    
    # Classify the text using LLM
    result = classifier(text)
    
    return jsonify(result)

if __name__ == '__main__':
    app.run(host='0.0.0.0', port=5000)
    `;

    const metadataEnrichment = `import openai
from flask import Flask, request, jsonify

app = Flask(__name__)

# Configure OpenAI API Key
openai.api_key = 'your-openai-api-key'

@app.route('/enrich', methods=['POST'])
def enrich_data():
    data = request.json
    product_description = data.get('description')

    # Use LLM to generate metadata
    response = openai.Completion.create(
        engine="text-davinci-003",
        prompt=f"Generate metadata for the following product description: {product_description}",
        max_tokens=50
    )
    
    metadata = response.choices[0].text.strip()

    return jsonify({"metadata": metadata})

if __name__ == '__main__':
    app.run(host='0.0.0.0', port=5001)
    `;

    const apiEnhancement = `import openai
from flask import Flask, request, jsonify

app = Flask(__name__)

# Configure OpenAI API Key
openai.api_key = 'your-openai-api-key'

@app.route('/query', methods=['POST'])
def query_api():
    data = request.json
    user_query = data.get('query')

    # Generate a response based on user query using LLM
    response = openai.Completion.create(
        engine="text-davinci-003",
        prompt=f"Answer the following query: {user_query}",
        max_tokens=100
    )
    
    answer = response.choices[0].text.strip()

    return jsonify({"answer": answer})

if __name__ == '__main__':
    app.run(host='0.0.0.0', port=5002)
    `;

    const logAnalyzer = `import openai
from flask import Flask, request, jsonify

app = Flask(__name__)

# Configure OpenAI API Key
openai.api_key = 'your-openai-api-key'

@app.route('/analyze-logs', methods=['POST'])
def analyze_logs():
    data = request.json
    logs = data.get('logs')

    # Use LLM to analyze logs and provide insights
    response = openai.Completion.create(
        engine="text-davinci-003",
        prompt=f"Analyze the following logs and provide insights: {logs}",
        max_tokens=200
    )
    
    insights = response.choices[0].text.strip()

    return jsonify({"insights": insights})

if __name__ == '__main__':
    app.run(host='0.0.0.0', port=5003)
    `;

    const [modalVisible, setModalVisible] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);

    return (
        <Content className={"pad-content"}>
            <Space direction={"vertical"} Gap>
                <Space direction={"vertical"} GapSm>
                    <Title Large className={"heavy"}>Chatbots aren’t the best use of Large Language Models
                        anymore.</Title>
                    <Space GapSm>
                        <small>Written by Gordon Goodrum</small>
                        &bull;
                        <small className={"muted"}>Edited by OakFrame Composition LLM</small>
                        &bull;
                        <small className={"muted"}>&copy; 2024</small>
                    </Space>
                </Space>

                <p>
                    As a small or medium-sized business owner, you’ve likely encountered chatbots powered by Large
                    Language Models (LLMs), marketed as the future of customer interaction. These chatbots can automate
                    interactions, answer queries, and provide customer support, but their application is often limited
                    to these front-facing functions. While useful, this is only scratching the surface of what LLMs can
                    offer your business. The most powerful and transformative use of LLMs lies in their integration into
                    your business's core systems, driving operational efficiencies, providing real-time analytics, and
                    enhancing decision-making processes.
                </p>

                <h2>Chatbots vs. Integrated Systems: A Deep Dive</h2>
                <p>
                    The primary limitation of chatbots is their restricted scope. They typically handle routine,
                    repetitive tasks like answering frequently asked questions (FAQs) or managing basic customer
                    service. These applications, while convenient, do not fully leverage the advanced capabilities of
                    LLMs, such as their ability to understand complex patterns, generate actionable insights, or predict
                    behaviors.
                </p>

                <p>
                    When integrated into backend systems, LLMs can drive substantial improvements in business
                    operations. For example, LLMs can analyze customer data in real-time to optimize marketing
                    campaigns, automate content generation based on customer preferences, and predict future customer
                    behaviors with unprecedented accuracy. By embedding LLMs into your existing infrastructure, you
                    create a more intelligent, adaptable, and efficient organization.
                </p>

                <h3>Maximizing Business Efficiency Through LLM Integration</h3>
                <p>
                    The impact of LLMs extends far beyond conversational interfaces. Let’s explore key areas where LLMs
                    can revolutionize your business processes:
                </p>


                    <Row GapSm>

                    <Col xs={24} md={12} xl={6}><strong><div className={"inline-box circle"} style={{backgroundColor:"#a4cbea"}}/> Data Enrichment</strong><Divider/>
                        <Small className="muted">LLMs can automatically extract insights from unstructured data
                        such as customer reviews, social media mentions, or sales data, enabling you to make informed
                            decisions in real-time.</Small>
                    </Col>
                    <Col  xs={24} md={12} xl={6}><strong><div className={"inline-box circle"} style={{backgroundColor:"#a4cbea"}}/> Predictive Analytics</strong><Divider/>
                        <Small className="muted">By leveraging historical data, LLMs can predict future
                        customer behaviors, such as purchase intent or churn risk, enabling you to take proactive
                            measures.</Small>
                    </Col>
                    <Col xs={24} md={12} xl={6}><strong><div className={"inline-box circle"} style={{backgroundColor:"#a4cbea"}}/> Content Creation</strong><Divider/>
                        <Small className="muted">LLMs can generate high-quality content for blogs,
                        social media posts, product descriptions, or personalized marketing emails, drastically reducing
                            the time and effort required to maintain a strong online presence.</Small>
                    </Col>
                    <Col xs={24} md={12} xl={6}><strong><div className={"inline-box circle"} style={{backgroundColor:"#a4cbea"}}/> Sentiment Analysis</strong><Divider/>
                        <Small className="muted">LLMs can analyze customer feedback, reviews, and social
                            media interactions to understand customer sentiment and adjust business strategies accordingly.</Small>
                    </Col>
                    </Row>


                <h4>Transforming the Value Chain with LLM Integration</h4>
                <p>
                    By integrating LLMs into your core processes, your business can automate routine tasks, reduce
                    operational overhead, and unlock insights that would have previously required manual intervention.
                    These integrations are not limited to front-end customer interaction. LLMs can function as cognitive
                    engines that analyze large volumes of data, enhancing both back-end processes and strategic
                    decision-making.
                </p>

                <Row>
                    <Col span={12}>
                        <h3>Comparative Use of LLMs: Chatbots vs Integrated Systems</h3>
                    </Col>
                </Row>

                <Row Gap>
                    <Col xs={24} md={24}>
                        <Card className={"default"} Pad>
                        <Space direction="vertical" GapSm>
                            <strong><div className={"inline-box circle"} style={{backgroundColor:"#a4cbea"}}/> Chatbots</strong>
                            <Small className="muted">Primarily handle customer-facing interactions, such as FAQs or basic queries.</Small>
                            <Small className="muted">Often limited to predefined conversation trees, making them less adaptable to complex queries.</Small>
                            <Small className="muted">Useful for automating customer service but limited in scope and intelligence.</Small>
                            <Small className="muted">Low impact on overall operational efficiency, as their scope is limited to specific tasks.</Small>
                        </Space></Card>
                    </Col>
                    <Col xs={24} md={24} >
                        <Card className={"active"} Pad>

                        <Space direction="vertical" GapSm>
                            <strong><div className={"inline-box circle"} style={{backgroundColor:"#a4cbea"}}/> Integrated Systems</strong>
                            <Small>Can operate behind the scenes, optimizing internal processes like supply chain management, inventory control, and real-time analytics.</Small>
                            <Small>LLMs integrated into backend systems can dynamically process data, analyze patterns, and even autonomously make decisions.</Small>
                            <Small>Capable of generating deep insights from customer data, enabling predictive analytics, automated reporting, and strategic business forecasting.</Small>
                            <Small>High impact across various departments: marketing, logistics, HR, finance, and more, increasing overall business intelligence and efficiency.</Small>
                        </Space></Card>
                    </Col>
                </Row>



                <h2>Key LLM Use Cases Beyond Chatbots</h2>
                <p>
                    While chatbots continue to dominate the conversation around LLMs, it is crucial for business owners
                    to understand the broader potential of this technology. Below are key use cases that demonstrate how
                    LLMs, when integrated into your core systems, can create lasting value across your business.
                </p>

                <Row Gap>
                    <Col xs={24} sm={12}>
                        <Card className="full">
                            <Content Pad className={"full"}>
                                <Space direction="vertical" Gap justify={"space-between"} Full>
                                    <Space direction={"vertical"} GapSm >
                                        <Space GapSm NoWrap align={"center"}>
                                            <FontAwesomeIcon icon={faChartLine} fixedWidth className={"muted-heavy"}/>
                                            <h3>Real-Time Customer Data Analysis</h3>
                                        </Space>
                                        <Divider/>
                                        <Paragraph>
                                            An integrated LLM can analyze customer data in real-time, identifying
                                            patterns and trends as they emerge. This capability allows you to react
                                            instantly to market changes, pivoting your strategy without delays.
                                        </Paragraph>
                                    </Space>
                                    <Small className="muted">LLM integration ensures faster decision-making by
                                        automating data analysis from multiple sources like social media, sales, and
                                        feedback.
                                    </Small>
                                </Space>
                            </Content>
                        </Card>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Card className="full">
                            <Content Pad className={"full"}>
                                <Space direction="vertical" Gap justify={"space-between"} Full>
                                <Space direction="vertical" GapSm>
                                    <Space GapSm NoWrap align={"center"}>
                                        <FontAwesomeIcon icon={faBullhorn} fixedWidth className={"muted-heavy"}/>
                                        <h3>Intelligent Marketing Automation</h3>
                                    </Space>
                                    <Divider/>
                                    <Paragraph>
                                        LLMs can automate content generation for marketing campaigns, tailoring it to
                                        individual customer preferences. From personalized email campaigns to targeted
                                        social media content, LLMs generate everything from subject lines to complete
                                        articles.
                                    </Paragraph>

                                </Space><Small className="muted">LLMs optimize content creation to drive conversions with
                                    data-driven marketing strategies.</Small>
                                </Space>
                            </Content>
                        </Card>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Card className="full">
                            <Content Pad className={"full"}>
                                <Space direction="vertical" Gap justify={"space-between"} Full>
                                <Space direction="vertical" Gap>
                                    <Space direction={"vertical"}>
                                        <FontAwesomeIcon icon={faTruckLoading} fixedWidth className={"muted-heavy"}/>
                                        <Title Large>Supply Chain Optimization</Title>
                                    </Space>
                                    <Paragraph>
                                        LLMs can analyze supplier data, forecast demand, and predict supply chain
                                        disruptions before they happen. Integrating LLMs into your inventory management
                                        system reduces waste and improves efficiency.
                                    </Paragraph>

                                </Space>
                                    <Small className="muted">By optimizing supply chain management, LLMs help businesses
                                        maintain optimal stock levels and mitigate risks.</Small>
                                </Space>
                            </Content>
                        </Card>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Card className="full">
                            <Content Pad className={"full"}>
                                <Space direction="vertical" Gap justify={"space-between"} Full>
                                <Space direction="vertical" Gap>
                                    <Space direction={"vertical"}>
                                        <FontAwesomeIcon icon={faUsersCog} fixedWidth className={"muted-heavy"}/>
                                        <Title Large>Workforce Management</Title>
                                    </Space>
                                    <Paragraph>
                                        LLMs can automate employee scheduling, predict workforce needs based on
                                        historical data, and monitor employee engagement through sentiment analysis.
                                        Analyzing feedback from surveys or performance reviews helps managers improve
                                        team dynamics.
                                    </Paragraph>

                                </Space>
                                    <Small className="muted">LLMs streamline workforce management by automating
                                        scheduling and analyzing employee morale.</Small>
                                </Space>
                            </Content>
                        </Card>
                    </Col>
                </Row>


                <h2>The Future of LLMs: Transforming Business Operations</h2>
                <p>
                    While the initial wave of LLM adoption focused on chatbots and conversational agents, the future
                    lies in integrating these powerful tools into every aspect of business operations. LLMs can act as
                    the backbone of intelligent business systems, processing data at scale, automating routine tasks,
                    and providing strategic insights that empower business leaders to make informed, data-driven
                    decisions.
                </p>

                <p>
                    As LLMs continue to evolve, their integration into business ecosystems will unlock new opportunities
                    for growth and innovation. Whether you're looking to optimize your supply chain, improve customer
                    satisfaction, or drive marketing success, LLMs offer unprecedented capabilities that can transform
                    your business from reactive to proactive, from manual to automated, and from data-rich to
                    insight-driven.
                </p>

                <h3>Key Takeaways:</h3>
                <ul>
                    <Space direction={"vertical"} GapSm>
                    <li><strong>Beyond Chatbots:</strong> LLMs have transformative potential far beyond simple customer
                        interaction tools. They can revolutionize internal processes and provide key insights to enhance
                        decision-making.
                    </li>
                    <li><strong>Integrated Systems:</strong> Businesses that fully integrate LLMs into their operations
                        can automate processes, reduce costs, and increase efficiency across departments.
                    </li>
                    <li><strong>Proactive Decision-Making:</strong> By leveraging predictive analytics and real-time
                        data processing, LLMs enable businesses to anticipate customer needs and market shifts rather
                        than simply react to them.
                    </li>
                    </Space>
                </ul>

                <BlockWarning className={"huge"}>It gets a bit technical from here, <a href={"#"}><strong>Send this
                    Page</strong></a> to your development team.</BlockWarning>

                <Title Large>Insightful Processing: Unlock Data-Driven Intelligence</Title>
                <Paragraph>
                    Many businesses think of chatbots as the only way to utilize Large Language Models (LLMs), but
                    that’s just scratching the surface. Imagine having an intelligent system that goes beyond customer
                    interactions, integrating seamlessly into your backend to supercharge your data processing. LLMs can
                    help you automate repetitive tasks, make sense of large amounts of unstructured data, and derive
                    meaningful insights in real-time.
                </Paragraph>
                <Row Gap>
                    <Col xs={12}>
                        <Paragraph>
                            Take customer feedback, for instance. Instead of manually sifting through reviews or
                            surveys, an LLM can categorize and analyze them automatically, allowing you to respond
                            faster and make more informed decisions. This isn’t just automation—it’s turning raw data
                            into actionable insights that can improve your customer satisfaction, operational
                            efficiency, and overall business strategy.
                        </Paragraph>
                    </Col>
                    <Col xs={12}>
                        <Paragraph>
                            Below is an example of a Python microservice that leverages the power
                            of <Pill>transformers</Pill> to classify customer feedback in real-time. By implementing
                            this in your backend, you can instantly categorize feedback and prioritize key issues or
                            opportunities, giving you a competitive edge.
                        </Paragraph>
                    </Col>
                </Row>


                <Row Gap>
                    <Col xs={24} md={12}><CM raw={textClassifier} language={"python"}/></Col>
                    <Col xs={24} md={12}>
                        <Result
                            className={"full align-stretch"}
                            status="success"
                            title="Open Source Docker Image Integration"
                            subTitle={
                                <Space direction={"vertical"} GapSm>
                                    <Paragraph><strong>Operational Excellence:</strong> 100% GPU Usage</Paragraph>
                                    <Paragraph>Last Verified: September 6th, 2024</Paragraph>
                                    <Paragraph>Seamlessly integrate this example into your <strong>Data
                                        Pipeline</strong> to enhance efficiency and scalability.</Paragraph>
                                </Space>
                            }
                            extra={
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        if (!modalVisible) {
                                            setModalVisible(true);
                                        }
                                    }}
                                >
                                    Explore the <strong>Dockerfile</strong> Setup
                                </Button>
                            }
                        />
                    </Col>

                </Row>


                <p>
                    This microservice accepts text via a POST request and returns the classification result (e.g.,
                    whether a customer review expresses positive or negative sentiment).
                </p>

                <h3>Data Enrichment</h3>
                <p>
                    Imagine using LLMs to automatically enrich the data in your product catalog by generating
                    SEO-optimized metadata for each product. This would save time for your marketing team and help
                    improve search engine rankings. Below is an example of a Python microservice using OpenAI’s GPT
                    model to generate metadata for a product description.
                </p>

                <Collapsible title={<span><FontAwesomeIcon className={"muted-heavy"} icon={faPython} fixedWidth/> <strong>Python Code Sample</strong> - Data Enrichment</span>} >
                <CM raw={metadataEnrichment} language={"python"}/>
                </Collapsible>

                <h3>API Enhancement</h3>
                <p>
                    LLMs can enhance your existing APIs by making them smarter and more responsive to user queries. This
                    can help you improve customer support, enhance product recommendations, or automate business
                    processes. Below is an example of an enhanced API that responds with intent-based answers using a
                    language model.
                </p>

                <Collapsible title={<span><FontAwesomeIcon className={"muted-heavy"} icon={faPython} fixedWidth/> <strong>Python Code Sample</strong> - Data Enhancement</span>} >
                <CM raw={apiEnhancement} language={"python"}/>
                </Collapsible>

                <h3>LLMs in Microservice Architecture</h3>
                <p>
                    LLMs are especially well-suited for microservice architectures due to their flexibility and
                    scalability. You can introduce an LLM-powered service to analyze unstructured data, such as logs,
                    without interrupting the core operations of your business. Below is an example of a Python
                    microservice that uses LLMs to analyze logs and provide insights.
                </p>

                <Collapsible title={<span><FontAwesomeIcon className={"muted-heavy"} icon={faPython} fixedWidth/> <strong>Python Code Sample</strong> - Data Analysis</span>} >

                <CM raw={logAnalyzer} language={"python"}/>
                </Collapsible>

                <p>
                    This system allows you to gain valuable insights into your operations, helping you troubleshoot
                    problems faster and optimize business processes.<br/><strong>Almost.</strong>
                </p>

                <h3>Improving Results & Continuous Delivery</h3>
                <p>
                    The results we get from a few of the *flagship* LLMs, such as ChatGPT, Anthropic, Llama3.1 are very
                    consistent, however, generally fail in more specific / domain focused tasks.
                </p>


                <h3>Conclusion</h3>
                <p>
                    While chatbots are a popular use of LLMs, they are far from being the most effective or
                    transformative application for small and medium businesses. LLMs can do more than just chat—they can
                    automate data enrichment, provide insightful analysis, and improve the intelligence of your APIs.
                    Integrating them into your business’s microservice architecture will unlock the full potential of
                    LLMs and provide significant returns.
                </p>

                <Divider/>
                <Modal
                    visible={modalVisible}
                    title={<span>Deployment and Usage</span>}
                    content={
                        <Space direction={"vertical"} Gap>
                            <p>
                                Deploying these Python microservices is straightforward, especially when using Docker
                                Compose to manage multiple services. Docker Compose allows you to define and manage
                                multi-container Docker applications, ensuring that each microservice runs in an isolated
                                environment and shares resources such as volumes where necessary.
                                Below is a `docker-compose.yml` file that sets up a Python-based microservice using the
                                `cfx-transformer` image, with a shared volume.
                            </p>

                            <Tabs
                                tabs={[
                                    {
                                        index: "Dockerfile",
                                        children: (
                                            <CM
                                                language={"python"}
                                                raw={`# Use an official Python runtime as a parent image
FROM python:3.9-slim

# Set the working directory
WORKDIR /app

# Copy the current directory contents into the container at /app
COPY . /app

# Install any necessary dependencies
RUN pip install --no-cache-dir flask transformers openai

# Make port 5000 available to the world outside this container
EXPOSE 5000

# Define environment variable
ENV FLASK_APP=main.py

# Run the microservice
CMD ["flask", "run", "--host=0.0.0.0", "--port=5000"]`}
                                            />
                                        )
                                    }, {
                                        index: "docker-compose.yml",
                                        children: (
                                            <CM
                                                language={"yaml"}
                                                raw={`version: '3'
services:
  transformer-service:
    image: cfx-transformer/python:latest
    container_name: transformer_service
    volumes:
      - ./app:/app
    ports:
      - "5000:5000"
    environment:
      - FLASK_APP=main.py
    command: flask run --host=0.0.0.0 --port=5000
  insights-service:
    image: cfx-transformer/insights:latest
    container_name: insights_service
    volumes:
      - ./app:/app
    ports:
      - "5001:5001"
    depends_on:
      - transformer-service
    command: flask run --host=0.0.0.0 --port=5001
volumes:
  app:`}
                                            />
                                        )
                                    },
                                    {
                                        index: "File Structure",
                                        children: <FileSystemTree/>
                                    }
                                ]}
                                defaultTab="docker-compose.yml"
                                onTabChange={(tab) => console.log('Active Tab:', tab)}
                            />
                            <Paragraph>Once you've got the Repository, you can simply run <Pill>docker compose up
                                -d</Pill>, and your API endpoint should soon be available locally!</Paragraph>
                        </Space>
                    }
                    onClose={() => setModalVisible(false)}
                />


                <Drawer
                    visible={drawerVisible}
                    title={<Title Large>LLMs in Action</Title>}
                    content="See how LLMs are transforming industries by integrating into microservices."
                    onClose={() => setDrawerVisible(false)}
                />

            </Space>
        </Content>
    );
};
