import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import {faCircleXmark, faStar as regularStar} from '@fortawesome/free-regular-svg-icons';
import {Space} from "../layout/Content";

interface StarRatingProps {
    initialRating: number;
    maxRating?: number;
    onRatingChange: (newRating: number) => void;
}

const StarRating: React.FC<StarRatingProps> = ({ initialRating, maxRating = 5, onRatingChange }) => {
    const [rating, setRating] = useState<number>(initialRating);

    const handleRatingChange = (newRating: number) => {
        if (newRating !== rating){
        setRating(newRating);
        onRatingChange(newRating);}else{
            setRating(0);
            onRatingChange(0);
        }
    };

    return (
        <Space GapSm>
            <FontAwesomeIcon
                key={0}
                icon={faCircleXmark}
                style={{ color: 1 <= rating ? 'grey' : 'lightgray', cursor: 'pointer' }}
                onClick={() => handleRatingChange(0)}
            />
            {Array.from({ length: maxRating }, (_, index) => {
                const starIndex = index + 1;
                return (
                    <FontAwesomeIcon
                        key={starIndex}
                        icon={starIndex <= rating ? solidStar : regularStar}
                        style={{ color: starIndex <= rating ? '#f39d00' : 'grey', cursor: 'pointer' }}
                        onClick={() => handleRatingChange(starIndex)}
                    />
                );
            })}
        </Space>
    );
};

export default StarRating;
