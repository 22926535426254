import React, {useEffect, useState} from 'react';
import {Button, Input, Row, Col, Space, Title} from '../layout/Content';
import {useNavigate} from 'react-router-dom';
import CM from "./CodeMirror";
import StarRating from "../part/StarRating";

export interface IInferenceDataSchema {
    schema?: string[];
    data?: any[];
    system?: string;
    prompt: string;
    response: string;
    intent?: string;
}

export interface FineTuneModel {

    uuid?: string;
    data: IInferenceDataSchema;
    task: string;
    options: {
        public: boolean;
        is_json?: boolean;
    };
    labels: {
        rating: number;
        human_labeled: boolean;
    };

}

interface FineTuneProps {
    finetune?: FineTuneModel;
}

const decodeJSONExists = (v: any, def: any) => {
    if (v === undefined) {
        return def;
    }
    if (v) {
        return JSON.stringify(v);
    }
    return def;
}

// Main FineTuneConnectorForm Component
const FineTuneConnectorForm: React.FC<FineTuneProps> = ({finetune}) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [task, setTask] = useState(finetune?.task || 'inference');
    const [schema, setSchema] = useState(decodeJSONExists(finetune?.data?.schema, '[]'));
    const [results, setResults] = useState("[]");
    const [prompt, setPrompt] = useState(finetune?.data?.prompt || '');
    const [data, setData] = useState(decodeJSONExists(finetune?.data?.data, '[]'));
    const [response, setResponse] = useState(finetune?.data?.response || "");
    const [publicOption, setPublicOption] = useState(finetune?.options?.public || false);
    const [jsonOption, setJSONOption] = useState(finetune?.options?.is_json || false);
    const [rating, setRating] = useState(finetune?.labels?.rating || 0);
    const navigate = useNavigate();

    // Determine if the form is in edit mode
    useEffect(() => {
        if (finetune && finetune.uuid) {
            setIsEditMode(true);
        }
    }, [finetune]);

    // Handle form submission
    const handleSubmit = async () => {
        const fineTuneData: FineTuneModel = {
            task,
            data: {
                prompt,
                schema: JSON.parse(schema),
                data: JSON.parse(data),
                //results: JSON.parse(results || "[]"),
                response,
            },
            options: {
                public: publicOption,
            },
            labels: {
                rating,
                human_labeled: true
            }
        };

        try {
            const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/finetune${isEditMode ? `/${finetune?.uuid}` : ''}`, {
                method: isEditMode ? 'PUT' : 'POST',
                mode: 'cors',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(fineTuneData),
            });

            if (!response.ok) {
                throw new Error('Failed to save fine-tune data');
            }

            const result = await response.json();
            console.log('Fine-tune data saved successfully:', result);

            navigate('/databox/tuning');
        } catch (error) {
            console.error('Error saving fine-tune data:', error);
        }
    };

    return (
        <Space direction="vertical" Gap>
            <Row Gap>
                <Col xs={24}>
                    <Title Large>{isEditMode ? 'Edit Fine-tune Example' : 'Create New Fine-tune Example'}</Title>
                </Col>
            </Row>

            <Row Gap>
                <Col xs={24} md={12}>
                    <Space direction="vertical" Gap>
                        <label>Task:</label>
                        <select value={task} onChange={(e) => setTask(e.target.value)}>
                            <option value="inference">Inference</option>
                            <option value="moderation">Moderation</option>
                            <option value="alignment">Alignment</option>
                            <option value="results">Results</option>
                        </select>
                    </Space>
                </Col>

                <Col xs={12} md={6}>
                    <Space direction="vertical" Gap>
                        <label>Public:</label>
                        <input type="checkbox" checked={publicOption}
                               onChange={(e) => setPublicOption(e.target.checked)}/>
                    </Space>
                </Col>

                <Col xs={12} md={6}>
                    <Space direction="vertical" Gap>
                        <label>Is JSON:</label>
                        <input type="checkbox" checked={jsonOption} onChange={(e) => setJSONOption(e.target.checked)}/>
                    </Space>
                </Col>

                <Col xs={12} md={6}>
                    <Space direction="vertical" Gap>
                        <label>Rating:</label>
                        <StarRating initialRating={rating} onRatingChange={(e: number) => setRating(e)}/>
                    </Space>
                </Col>

                <Col xs={24}>
                    <Space direction="vertical" Gap>
                        <label>Prompt:</label>
                        <Input Grow type="text" value={prompt} onChange={(e) => setPrompt(e.target.value)}/>
                    </Space>
                </Col>

                {((task === "alignment" || task === "results") || schema) && <Col xs={24} md={12}>
                    <Space direction="vertical" Gap>
                        <label>Schema:</label><CM raw={(schema)} onChange={(e: any) => {
                        try {
                            setSchema((e.target.value))
                        } catch (e) {
                            console.error(e)
                        }
                    }}/>
                    </Space>
                </Col>}


                {((task === "results" || task === "alignment") || data) && <Col xs={24} md={12}>
                    <Space direction="vertical" Gap>
                        <label>Data:</label><CM raw={(data)} onChange={(e: any) => {
                        try {
                            setData((e.target.value))
                        } catch (e) {
                            console.error(e)
                        }
                    }}/>
                    </Space>
                </Col>}

                <Col xs={24} md={24}>
                    <Space direction="vertical" Gap>
                        <label>Response:</label>
                        {(jsonOption || task === "moderation" || task === "results" || task === "alignment") ?
                            <CM language={"jsx"} raw={(response)} onChange={(e: any) => {
                                setResponse(e.target.value);
                            }}/> :
                            <Input Grow type="text" value={response} onChange={(e) => setResponse(e.target.value)}/>}
                    </Space>
                </Col>

            </Row>

            <Row Gap>
                <Col xs={24}>
                    <Button type="primary" onClick={handleSubmit}>
                        {isEditMode ? 'Save Fine-tune' : 'Create Fine-tune'}
                    </Button>
                </Col>
            </Row>
        </Space>
    );
};

export default FineTuneConnectorForm;
