import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFile, faFileCode } from '@fortawesome/free-solid-svg-icons';
import './FileSystemTree.scss';
import {Space} from "../layout/Content";

type FileNode = {
    type: 'file';
    name: string;
    icon?: any; // FontAwesome icon
};

type FolderNode = {
    type: 'folder';
    name: React.ReactNode;
    children: TreeNode[];
};

type TreeNode = FileNode | FolderNode;

const treeData: TreeNode[] = [
    {
        type: 'folder',
        name: <span>/ <span className={"muted-heavy"}>(project root)</span></span>,
        children: [
            { type: 'file', name: 'Dockerfile', icon: faFileCode },
            { type: 'file', name: 'docker-compose.yml', icon: faFileCode },
            { type: 'file', name: 'main.py', icon: faFileCode },
            { type: 'file', name: 'requirements.txt', icon: faFile },
            {
                type: 'folder',
                name: 'app',
                children: [
                    { type: 'file', name: 'config.json', icon: faFile },
                    { type: 'file', name: 'service.py', icon: faFileCode },
                ],
            },
        ],
    },
];

const FileSystemTree: React.FC = () => {


    const [selectedNode, setSelectedNode] = useState<string|null>(null);

    const handleClick = (e:TreeNode)=>{
        console.log(e);
        setSelectedNode(e.name as string);
    }

    const renderTree = (nodes: TreeNode[]): React.ReactNode => (

        <ul className="file-tree">
            {nodes.map((node, index) => (
                <li key={index} className="file-tree__item">
                    {node.type === 'folder' ? (
                        <div className={"folder"}>
                            <span className={"item"} onClick={()=>{
                                handleClick(node);
                            }}><FontAwesomeIcon icon={faFolder} className="file-tree__icon" /> {node.name}</span>
                            {node.children && renderTree(node.children)}
                        </div>
                    ) : (
                        <div className={"item"} onClick={()=>{
                            handleClick(node);
                        }}>
                            <FontAwesomeIcon icon={node.icon || faFile} className="file-tree__icon" /> {node.name}
                        </div>
                    )}
                </li>
            ))}
        </ul>

    );

    return <Space direction={"horizontal"} NoWrap Gap>{renderTree(treeData)}   <div>details {selectedNode&&(
        <Space direction={"vertical"} GapSm>
            {selectedNode}
        </Space>
    )}</div>
    </Space>;
};

export default FileSystemTree;
