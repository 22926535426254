import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Divider, Input, Row, Space, Title, Pill} from "../layout/Content";
import {useNavigate} from "react-router-dom";

// Enum for model tasks
enum ModelTask {
    Completion = "completion",
    Instruct = "instruct",
    Moderation = "moderation",
    Alignment = "alignment",
    Results = "results"
}

// Reusable SelectionCard Component
interface SelectionCardProps {
    title: string;
    description: string;
    isSelected: boolean;
    onClick: () => void;
    details: string[];
    pills?: string[];
    disabled?: boolean;
}

const SelectionCard: React.FC<SelectionCardProps> = ({
                                                         title,
                                                         description,
                                                         isSelected,
                                                         onClick,
                                                         details,
                                                         pills,
                                                         disabled
                                                     }) => (
    <Card Pad Full className={disabled ? "disabled" : (isSelected ? "active" : "")} onClick={disabled ? () => {
    } : onClick}>
        <Space Gap>
            <input type="checkbox" checked={isSelected} disabled={disabled}/>
            <h3>{title}</h3>
            <small>{description}</small>
            <ul>
                {details.map((detail, idx) => (
                    <li key={idx}>{detail}</li>
                ))}
            </ul>
            {pills && (
                <Space GapSm>
                    {pills.map((pill, idx) => (
                        <Pill key={idx}>{pill}</Pill>
                    ))}
                </Space>
            )}
        </Space>
    </Card>
);

const getValueFromDefault = (value: any, def: any) => {
    if (value === undefined) {
        return def
    } else {
        return value;
    }
}

const MLModelConnectorForm: React.FC<{ value?: any }> = ({value}) => {
    // State for common model settings
    const [isEditMode, setIsEditMode] = useState(false);

    const [name, setName] = useState(getValueFromDefault(value.name, ""));
    const [description, setDescription] = useState(getValueFromDefault(value.description, ""));
    const [requiresModeration, setRequiresModeration] = useState(getValueFromDefault(value.requires_moderation, ""));
    const [isInstructModel, setIsInstructModel] = useState(getValueFromDefault(value.is_instruct_model, ""));
    const [isExternal, setIsExternal] = useState(getValueFromDefault(value.is_external, ""));
    const [modelTask, setModelTask] = useState<ModelTask>(getValueFromDefault(value.task, ModelTask.Completion));

    // State for specific model type and configuration
    const [type, setType] = useState(getValueFromDefault(value.type, "clusterfx"));
    const [apiKey, setApiKey] = useState(getValueFromDefault(value.configuration.api_key, ""));
    const [connectionURI, setConnectionURI] = useState(getValueFromDefault(value.configuration.connectionURI, ""));
    const [model, setModel] = useState(getValueFromDefault(value.configuration.model, ""));

    const navigate = useNavigate();

    // Determine if the form is in edit mode
    useEffect(() => {
        if (value) {
            setIsEditMode(true);
        }
    }, [value]);

    // Handle form submission
    const handleSubmit = async () => {
        const postData = {
            name,
            description,
            requires_moderation: requiresModeration,
            is_instruct_model: isInstructModel,
            is_external: isExternal,
            model_task: modelTask,
            type,
            configuration: {}
        };

        // Add specific configurations based on the selected model type
        if (type === 'openai') {
            postData.configuration = {api_key: apiKey, model};
        } else if (type === 'ollama') {
            postData.configuration = {connectionURI, model};
        }

        console.log('Submitting data:', postData);

        try {
            const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/model${isEditMode ? `/${model?.uuid}` : ''}`, {
                method: isEditMode ? 'PUT' : 'POST',
                mode: 'cors',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
            });

            if (!response.ok) {
                throw new Error('Failed to save ML Model Connector');
            }

            const result = await response.json();
            console.log('MLModelConnector saved successfully:', result);

            //resetForm();
            navigate("/databox/models");
        } catch (error) {
            console.error('Error saving ML Model Connector:', error);
        }
    };

    /*const resetForm = () => {
        setName('');
        setDescription('');
        setRequiresModeration(false);
        setIsInstructModel(false);
        setIsExternal(false);
        setModelTask(ModelTask.Completion);
        setType("ClusterFX");
        setApiKey('');
        setConnectionURI('');
        setModel('');
    };*/

    return (
        <Space direction="vertical" Gap>
            <Row Gap>
                <Col xs={24}>
                    <Title Large>Add New Machine Learning Model</Title>
                </Col>

                <Col xs={24}>
                    <Space Gap>
                        <h2>Model Configuration</h2>
                        <Divider/>
                    </Space>
                </Col>
                <Col xs={24} md={12}>
                    <Space direction="vertical" Gap>
                        <Space GapSm align="center">
                            <label>Type:</label>
                            <select value={type} onChange={(e) => setType(e.target.value)}>
                                <option value="clusterfx">ClusterFX</option>
                                <option value="openai">OpenAI</option>
                                <option value="ollama">ollama</option>
                            </select>
                        </Space>

                        <Space Wide align={"center"} GapSm>
                            <label>Name:</label>
                            <Input Grow type="text" value={name} onChange={(e) => setName(e.target.value)}
                                   placeholder="Model Name"/>
                        </Space>
                        <Space Wide align={"center"} GapSm>
                            <label>Description:</label>
                            <Input Grow type="text" value={description} onChange={(e) => setDescription(e.target.value)}
                                   placeholder="Model Description"/>
                        </Space>

                        {type === 'openai' && (
                            <><Space direction="vertical">
                                <label>API Key:</label>
                                <Input type="text" value={apiKey} onChange={(e) => setApiKey(e.target.value)}
                                       placeholder="API Key"/>
                            </Space>
                                <Space direction="vertical">
                                    <label>Model:</label>
                                    <Input type="text" value={model} onChange={(e) => setModel(e.target.value)}
                                           placeholder="Model"/>
                                </Space>
                            </>
                        )}

                        {type === 'ollama' && (
                            <>
                                <Space direction="vertical">
                                    <label>Connection URI:</label>
                                    <Input type="text" value={connectionURI}
                                           onChange={(e) => setConnectionURI(e.target.value)}
                                           placeholder="Connection URI"/>
                                </Space>
                                <Space direction="vertical">
                                    <label>Model:</label>
                                    <Input type="text" value={model} onChange={(e) => setModel(e.target.value)}
                                           placeholder="Model"/>
                                </Space>
                            </>
                        )}
                    </Space>
                </Col>

                <Col xs={24}>
                    <Space Gap>
                        <h2>Model Details</h2>
                        <Divider/>
                    </Space>
                </Col>
                <Col xs={24} md={12}>
                    <Space direction="vertical" Gap>
                        <Space Wide align={"center"} GapSm>
                            <label>Model Task:</label>
                            <select value={modelTask} onChange={(e: any) => setModelTask(e.target.value as ModelTask)}>
                                {Object.values(ModelTask).map((task) => (
                                    <option key={task} value={task}>{task}</option>
                                ))}
                            </select>
                        </Space>
                    </Space>
                </Col>
                <Col xs={24} md={12}>
                    <Space direction="vertical" Gap>
                        <Space Gap><input type={"checkbox"} checked={requiresModeration}
                                          onChange={(e: any) => setRequiresModeration(e.target.checked)}/>Requires
                            Moderation</Space>
                        <Space Gap><input type={"checkbox"} checked={isInstructModel}
                                          onChange={(e: any) => setIsInstructModel(e.target.checked)}/>Instruct
                            Model</Space>
                        <Space Gap><input type={"checkbox"} checked={isExternal}
                                          onChange={(e: any) => setIsExternal(e.target.checked)}/>External Model</Space>
                    </Space>
                </Col>

            </Row>

            <Row Gap>
                <Col xs={24}>
                    <Button type="primary" onClick={handleSubmit}>Create ML Model Connector</Button>
                </Col>
            </Row>
        </Space>
    );
};

export default MLModelConnectorForm;
