import React, {useState} from 'react';
import {
    Button, Card,
    Col,
    Content,
    Divider,
    DropDown,
    Input,
    Layout, Link,
    Page,
    Paragraph,
    Row, Small,
    Space,
    Spacer,
    Title
} from '../layout/Content';
import {Header} from "../part/Header";
import {Footer} from "../part/Footer";
import {
    Announcement,
    Breadcrumb,
    Drawer,
    Empty, InlineImage,
    List,
    Modal,
    Pagination,
    Result, ScrollableContainer, ScrollableElement,
    Segmented,
    Skeleton,
    Statistic,
    Tabs, Tooltip, Select
} from "../layout/Defaults";
import UploadFile from "../part/UploadFile";
import LikeButton from "../part/LikeButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment} from "@fortawesome/free-regular-svg-icons/faComment";
import {faGenderless, faHeart, faMars, faNeuter, faTransgenderAlt, faVenus} from "@fortawesome/free-solid-svg-icons";
import {faHeart as faHeartRegular} from "@fortawesome/free-regular-svg-icons";
import Badge from "../part/Badge";


interface ColorComponentProps {
    name: string;
    colors?: string[];
}

const ColorComponent: React.FC<ColorComponentProps> = ({name, colors = []}) => {
    return (
        <Row Gap>
            <Col xs={12} md={6}>
                <div className={`Pad theme-background-${name}-DEFAULT`} style={{
                    width: "100%",
                    height: "30px"
                }}><Title style={{color: "white"}}>{name}</Title></div>
            </Col>
            {["100","200","300","400","500","600","700","800","900"].map((theme)=>{
                return <Col xs={4} md={2}>
                    <div className={`Pad theme-background-${name}-${theme} theme-color-${name}-${(1000-parseInt(theme))>300?"900":"100"}`} style={{width: "100%", height: "30px"}}>{theme}</div>
                </Col>;
            })}

        </Row>
    )
}

export function StyleGuide() {

    const [modal, setModal] = useState<boolean>(false);
    const [drawer, setDrawer] = useState<boolean>(false);

    return (
        <Layout className="style-guide">

            <Header/>

            <Page Grow>
                <Content>
                    <Space className={"limits pad"} direction={"vertical"} Gap>


                        <Row Gap>
                            <Col xs={12}>
                                <Space direction={"vertical"} Gap>
                                    <Breadcrumb items={["Design System", "Typography"]}/>

                                    <Space Gap align={"center"}><Title Large>Sora</Title><Title>Primary
                                        Font</Title></Space>
                                    <p>Aa Bb Cc Dd Ee Ff Gg</p>
                                    <p>Lorem ipsum dolor</p>
                                    <Space direction={"vertical"}>
                                        <h1>h1</h1>
                                        <h2>h2</h2>
                                        <h3>h3</h3>
                                        <h4>h4</h4>
                                        <h5>h5</h5>
                                        <h6>h6</h6>
                                    </Space>
                                </Space>
                            </Col>
                            <Col xs={12}>
                                <Space direction={"vertical"} Gap>
                                    <p><strong>The Quick Brown Fox Jumps Over The Lazy Dogs</strong></p>
                                    <p><InlineImage src={"/logo192.png"}/> The Quick Brown Fox Jumps Over The Lazy Dogs</p>
                                    <p><Small>The Quick Brown Fox Jumps Over The Lazy Dogs</Small></p>
                                </Space>
                            </Col>
                        </Row>


                        <Row Gap>
                            <Col xs={24}>
                                <Space direction={"vertical"} Gap>
                                    <Breadcrumb items={["Design System", "Color Library"]}/>

                                    <Space direction={"vertical"}>

                                        <h1>Primary</h1>
                                        <Row Gap>
                                            <Col xs={24} md={12}>
                                                <div className={"Pad"} style={{
                                                    width: "100%",
                                                    height: "60px",
                                                    backgroundColor: "#51bbfe"
                                                }}><Title Large style={{color: "white"}}>Maya Blue</Title></div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className={"Pad"} style={{
                                                    width: "100%",
                                                    height: "60px",
                                                    backgroundColor: "#002943"
                                                }}><Title Large style={{color: "white"}}>100</Title></div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className={"Pad"} style={{
                                                    width: "100%",
                                                    height: "60px",
                                                    backgroundColor: "#017cc9"
                                                }}><Title Large style={{color: "white"}}>300</Title></div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className={"Pad"} style={{
                                                    width: "100%",
                                                    height: "60px",
                                                    backgroundColor: "#97d7fe"
                                                }}><Title Large style={{color: "white"}}>700</Title></div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className={"Pad"} style={{
                                                    width: "100%",
                                                    height: "60px",
                                                    backgroundColor: "#dcf2ff"
                                                }}><Title Large style={{color: "#017cc9"}}>900</Title></div>
                                            </Col>
                                            <Col xs={24}><h1>Secondary Colors</h1></Col>
                                        </Row>

                                        <Space direction={"vertical"} GapSm>
                                            <ColorComponent name={"PRIMARY"}></ColorComponent>
                                            <ColorComponent name={"Ash_Gray"}></ColorComponent>
                                            <ColorComponent name={"Bittersweet"}></ColorComponent>
                                            <ColorComponent name={"Jet"}></ColorComponent>
                                        </Space>


                                        <h3>h3</h3>
                                        <h4>h4</h4>
                                        <h5>h5</h5>
                                        <h6>h6</h6>
                                    </Space>
                                </Space>
                            </Col>
                        </Row>


                        <Row Gap>
                            <Col xs={24} md={8}>
                                <Space direction={"vertical"} Gap>
                                    <Breadcrumb items={["Design System", "Components", "Input"]}/>


                                    <Space direction={"vertical"}><label>Input Empty</label>
                                        <Input placeholder={"Label"} type={"text"}/></Space>

                                    <Space direction={"vertical"}><Badge data={"yes"}><label>Email Empty</label></Badge>
                                        <Input placeholder={"Label"} type={"email"}/></Space>

                                    <Space direction={"vertical"}><label>Password Empty</label>
                                        <Input placeholder={"Label"} type={"password"}/></Space>

                                    <Space direction={"vertical"}><label>Input Error</label>
                                        <Input Error={true} placeholder={"Label"} type={"text"}/></Space>

                                </Space>
                            </Col>
                            <Col xs={24} md={8}>
                                <Space direction={"vertical"} Gap>
                                    <Breadcrumb items={["Design System", "Components", "Button"]}/>


                                    <Space direction={"vertical"}>
                                        <label>Default</label>
                                        <Space GapSm><Button>Default</Button><Button href={"/style"}>Active
                                            Link</Button></Space>
                                    </Space>

                                    <Space direction={"vertical"}>
                                        <label>Styled</label>
                                        <Space GapSm><Button type="primary">Primary</Button>
                                            <Button type="ghost">Ghost</Button><Button type="warning">Warning</Button>
                                            <Button type="danger">Danger</Button>
                                            <Button type="like"><FontAwesomeIcon icon={faHeart} /></Button>
                                            <Button type="link">Link</Button>
                                            <Button type="disabled">Disabled</Button></Space>
                                    </Space>

                                    <Space direction={"vertical"}>
                                        <label>Sized</label>
                                        <Space GapSm direction={"vertical"}>
                                            <Button size={"small"}>Small</Button>
                                            <Button size={"normal"}>Default</Button>
                                            <Button size={"large"}>Large</Button>
                                        </Space>
                                    </Space>

                                </Space>
                            </Col>

                            <Col xs={24} md={8}>
                                <Space direction={"vertical"} Gap>
                                    <Breadcrumb items={["Design System", "Components", "Form"]}/>


                                    <Space direction={"vertical"}>
                                        <label>Default</label>
                                        <Space GapSm><Button>Default</Button><Button href={"/style"}>Active
                                            Link</Button></Space>
                                    </Space>

                                    <Space direction={"vertical"}>
                                        <label>Select & Dropdowns</label>
                                        <Space GapSm>
                                            <select>
                                                <option>value 1</option>
                                            </select>
                                            <Select
                                                defaultValue={"venus"}
                                                options={[
                                                    {
                                                        value: 'mars',
                                                        element: <Space align="center" GapSm><FontAwesomeIcon icon={faMars} fixedWidth={true} /> Mars</Space>
                                                    },
                                                    {
                                                        value: 'venus',
                                                        element: <Space align="center" GapSm><FontAwesomeIcon icon={faVenus} fixedWidth={true} /> Venus</Space>
                                                    },
                                                    {
                                                        value: 'non-binary',
                                                        element: <Space align="center" GapSm><FontAwesomeIcon icon={faNeuter} fixedWidth={true} /> Non-Binary</Space>
                                                    },
                                                    {
                                                        value: 'transgender',
                                                        element: <Space align="center" GapSm><FontAwesomeIcon icon={faTransgenderAlt} fixedWidth={true} /> Transgender</Space>
                                                    },
                                                    {
                                                        value: 'intersex',
                                                        element: <Space align="center" GapSm><FontAwesomeIcon icon={faGenderless} fixedWidth={true} /> Intersex</Space>
                                                    }
                                                ]}
                                                onSelected={(value: string) => {
                                                    console.log("Selected Gender:", value);
                                                  //  onChange(value);
                                                }}
                                            />
                                            <DropDown/>
                                        </Space>
                                    </Space>

                                    <Space direction={"vertical"}>
                                        <label>File & Upload</label>
                                        <Space GapSm>
                                            <UploadFile/>
                                        </Space>
                                    </Space>

                                </Space>
                            </Col>
                        </Row>

                        <Spacer/>
                        <Row Gap>

                            <Col xs={24} md={8}>
                                <Space direction={"vertical"} Gap>
                                    <Breadcrumb items={["Design System", "Components", "Actions"]}/>


                                    <Space direction={"vertical"}>
                                        <label>Like</label>
                                        <Space GapSm><LikeButton uuid={""}/></Space>
                                    </Space>
                                    <Space direction={"vertical"}>
                                        <label>Tooltip</label>
                                        <Space GapSm><Tooltip message={"This is a Tooltip!"}><Button type={"disabled"}>Hover over me!</Button></Tooltip></Space>
                                    </Space>

                                    <Space direction={"vertical"}>
                                        <label>Pagination</label>
                                        <Space GapSm><Pagination current={1} total={5}
                                                                 onChange={(page) => console.log('Page:', page)}/></Space>
                                    </Space>

                                    <Space direction={"vertical"}>
                                    <label>Announcment</label>
                                        <Announcement><span>It gets better! <Link href={"#"}>Eventually</Link>...</span></Announcement>
                                </Space>

                                </Space>
                            </Col>

                            <Col xs={24} md={8}>
                                <Space direction={"vertical"} Gap>
                                    <Breadcrumb items={["Design System", "Components","Card"]}/>


                                    <Space direction={"vertical"}>
                                        <label>Card</label>

                                        <Card Wide Pad>
                                            <Space direction={"vertical"} GapSm>
                                                <Space>Inside Card Content.</Space>
                                                <Space justify={"space-between"} GapSm>
                                                    <LikeButton uuid={"0"}/>
                                                    <Button type={"ghost"}><FontAwesomeIcon icon={faComment}/> 0</Button>
                                                    <Button className={"Grow"} type={"link"} href="#" >share</Button>
                                                </Space>
                                            </Space>
                                        </Card>

                                    </Space>
                                    <Space direction={"vertical"}>
                                        <label>Empty</label>
                                        <Empty description="No Data"/>
                                    </Space>

                                </Space>
                            </Col>

                            <Col xs={24} md={8}>
                                <Space direction={"vertical"} Gap>
                                    <Breadcrumb items={["Design System", "Components","Results"]}/>

                                    <Space direction={"vertical"}>
                                        <label>Result Success</label>
                                        <Result status="success" title="Successfully Purchased"
                                                subTitle="Order number: 123456789"
                                                extra={<Button type="primary">Go to Dashboard</Button>}/>
                                    </Space>
                                    <Space direction={"vertical"}>
                                        <label>Result Error</label>
                                        <Result status="error" title="Failure in Action..."
                                                subTitle="Order number: 123456789"
                                                extra={<Button type="warning">Go to Dashboard</Button>}/>
                                    </Space>

                                </Space>
                            </Col>

                            <Col xs={24} md={8}>
                                <Space direction={"vertical"} Gap>
                                    <Breadcrumb items={["Design System", "Components","Feed"]}/>
                                </Space>
                                    <div>
                                        <label>Horizontal Scrollable</label>
                                        <ScrollableContainer>
                                            {[
                                                { imageSrc: 'https://via.placeholder.com/60', altText: 'Item 1' },
                                                { imageSrc: 'https://via.placeholder.com/60', altText: 'Item 2' },
                                                { imageSrc: 'https://via.placeholder.com/60', altText: 'Item 3 Longer' },
                                                { imageSrc: 'https://via.placeholder.com/60', altText: 'Item 4' },
                                                { imageSrc: 'https://via.placeholder.com/60', altText: 'Item 5' },
                                                { imageSrc: 'https://via.placeholder.com/60', altText: 'Item 5 Longer' },
                                                { imageSrc: 'https://via.placeholder.com/60', altText: 'Item 5 Longer' },
                                                { imageSrc: 'https://via.placeholder.com/60', altText: 'Item 5' },
                                                { imageSrc: 'https://via.placeholder.com/60', altText: 'Item 5' },
                                                { imageSrc: 'https://via.placeholder.com/60', altText: 'Item 5' },
                                            ].map((item, index) => (
                                                <ScrollableElement key={index} imageSrc={item.imageSrc} altText={item.altText} />
                                            ))}
                                        </ScrollableContainer>
                                    </div>
                                <Space direction={"vertical"} Gap>
                                    <Space direction={"vertical"}>
                                        <label>Result Error</label>
                                        <Result status="error" title="Failure in Action..."
                                                subTitle="Order number: 123456789"
                                                extra={<Button type="warning">Go to Dashboard</Button>}/>
                                    </Space>

                                </Space>
                            </Col>



                            <Col xs={16}>
                                <Space direction={"vertical"} Gap>


                                    {/* List Section */}
                                    <Row>
                                        <Col xs={24}>
                                            <Content>
                                                <Title>List</Title>
                                                <List items={['Item 1', 'Item 2', 'Item 3']}/>
                                            </Content>
                                        </Col>
                                    </Row>

                                    {/* Segmented Section */}
                                    <Row>
                                        <Col xs={24}>
                                            <Content>
                                                <Title>Segmented</Title>
                                                <Segmented options={['Option 1', 'Option 2']}
                                                           onChange={(option) => console.log('Selected:', option)}/>
                                            </Content>
                                        </Col>
                                    </Row>

                                    {/* Statistic Section */}
                                    <Row>
                                        <Col xs={24}>
                                            <Content>
                                                <Title>Statistic</Title>
                                                <Statistic title="Active Users" value={112893}/>
                                            </Content>
                                        </Col>
                                    </Row>

                                    {/* Tabs Section */}
                                    <Row>
                                        <Col xs={24}>
                                            <Content>
                                                <Title>Tabs</Title>
                                                <Tabs tabs={[{index:"tab1",children:<span>Content for Tab 1</span>}, {index:"tab2",children:<span>Content for Tab 2. Yeah.</span>}]} defaultTab="tab2"
                                                      onTabChange={(tab) => console.log('Active Tab:', tab)}/>
                                            </Content>
                                        </Col>
                                    </Row>

                                    {/* Modal Section */}
                                    <Row>
                                        <Col xs={24}>
                                            <Content>
                                                <Title>Modal</Title>
                                                <Button onClick={() => {
                                                    setModal(!modal)
                                                }}>Toggle</Button>
                                                <Modal visible={modal} title={<span>Example Modal</span>}
                                                       content="This is an example modal." onClose={() => {
                                                    console.log('Modal closed');
                                                    setModal(false);
                                                }}/>
                                            </Content>
                                        </Col>
                                    </Row>

                                    {/* Drawer Section */}
                                    <Row>
                                        <Col xs={24}>
                                            <Content>
                                                <Title Large>Drawer</Title>
                                                <Button onClick={() => {
                                                    setDrawer(!drawer)
                                                }}>Toggle</Button>
                                                <Drawer visible={drawer} title={<Title Large>Example Drawer</Title>}
                                                        content="This is an example drawer." onClose={() => {
                                                    console.log('Drawer closed');
                                                    setDrawer(false)
                                                }}/>
                                            </Content>
                                        </Col>
                                    </Row>

                                    {/* Skeleton Section */}
                                    <Row>
                                        <Col xs={24}>
                                            <Content>
                                                <Title>Skeleton<Skeleton rows={1} width={("Skeleton".length)}/></Title>
                                                <Skeleton rows={5}/>
                                            </Content>
                                        </Col>
                                    </Row>
                                </Space>
                            </Col>

                            <Col xs={24}>
                                <div className={"Card Frame"}>
                                    <Space Full align={"center"} justify={"center"}>
                                        <Card Pad>
                                            <Title Large>Card Title</Title>
                                            <p>This is the card description! It should be long enough to stretch the Space.</p>
                                            <Space Gap Stretch>
                                                <Button>P1</Button>
                                                <Button>P2</Button>
                                                <Button>P3</Button>
                                            </Space>
                                        </Card>
                                    </Space>
                                </div>
                            </Col>

                            <Col xs={24} md={12}>
                                <div id="intro">
                                    <Space>
                                        <div className="title Invert PadH PadV">That night, they did not sleep.</div>
                                    </Space>
                                    <Spacer/>
                                </div>
                                <div id="essence">
                                    <Space>
                                        <div className="title Muted">Essence</div>
                                    </Space>
                                    <div className="title">Mysteries of the Night</div>
                                    <blockquote>
                                        <p>"In the essence of the night, she came with the wind..."</p>
                                    </blockquote>
                                    <div className="Paragraph">
                                        An unknown force, quietly like the whisper of leaves, permeated their existence.
                                        This presence, unannounced and unrecognized, blended into the daily life and
                                        nightly rest. This force was so invisible that it seemed like a part of
                                        themselves, a part of their world.
                                    </div>
                                    <Spacer/>
                                </div>
                            </Col>
                            <Col xs={24} md={8}>
                            </Col>
                        </Row></Space>
                </Content>
                <Footer/>
            </Page>
        </Layout>
    );
}
