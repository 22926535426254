import React, {useEffect, useState} from "react";
import {Button, Card, Col, Content, Layout, Page, Paragraph, Pill, Row, Space, Title} from "../layout/Content";
import {useNavigate, useParams} from "react-router-dom";
import {
    faArrowUpFromBracket, faChartBar,
    faDiagramNext,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PaginatedCollectionList from "./PaginatedCollectionList";
import MLModelConnectorForm from "./MLModelConnectorForm";
import DatasourceConnectorForm from "./DatasourceConnectorForm";
import {useUserProfileContext} from "../../lib/UserContext";


const renderPostItem = (post: any) => {

    const vis = (type: string) => {
        return (
            <Col xs={24}>
                <Row GapSm>
                    <Col xs={12} md={4}>
                        <strong>Model Name</strong>
                        <Paragraph>{post.name || 'Unknown'}</Paragraph>
                    </Col>
                    <Col xs={12} md={4}>
                        <strong>Model Type</strong>
                        <Paragraph>{post.type || 'Unknown'}</Paragraph>
                    </Col>
                    <Col xs={12} md={4}>
                        <strong>Task</strong>
                        <Paragraph>{post.model_task || 'Unknown Task'}</Paragraph>
                    </Col>
                    <Col xs={12} md={4}>
                        <strong>Requires Moderation</strong>
                        <Paragraph>{post.requires_moderation ? 'Yes' : 'No'}</Paragraph>
                    </Col>
                    <Col xs={12} md={4}>
                        <strong>Instruct Model</strong>
                        <Paragraph>{post.is_instruct_model ? 'Yes' : 'No'}</Paragraph>
                    </Col>
                    <Col xs={12} md={4}>
                        <strong>External Model</strong>
                        <Paragraph>{post.is_external ? 'Yes' : 'No'}</Paragraph>
                    </Col>
                </Row>
            </Col>
        );
    };

    return (
        <Card Pad>
            <Row GapSm>
                {vis(post.type)}
            </Row>
        </Card>
    );
}


const MLModel: React.FC = () => {

    const {page} = useParams<{ page: string }>();
    const {controller} = useParams<{ controller: string }>();
    const {uuid} = useParams<{ uuid: string }>();
    const { queryUserProfile, getUserProfile, setUserProfile, hasQueriedUserProfile } = useUserProfileContext();
    const user = getUserProfile();
    const [dataSource, setDataSource] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);


    const navigate = useNavigate();

    // Fetch fine-tune data by UUID
    useEffect(() => {
        const fetchFineTune = async () => {
            if (controller === "edit" && uuid) {
                try {
                    const response = await fetch(`https://api.clusterfx.org/model/${uuid}`, {
                        mode: 'cors',
                        credentials:'include',
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });

                    if (response.ok) {
                        const result = await response.json();
                        if (result.results.length > 0) {
                            setDataSource(result.results[0]);
                        }
                    }
                } catch (error) {
                    console.error("Failed to fetch model:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchFineTune();
    }, [controller, uuid]);

    const btn = () => {
        return <Button type={"primary"} onClick={() => {
            navigate("/databox/models/add");
        }}><FontAwesomeIcon icon={faPlus} fixedWidth/>Add New Model</Button>;
    };

    if (controller === "add") {
        return <MLModelConnectorForm/>;
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (controller === "edit" && dataSource && user && dataSource.owner.uuid === user.uuid) {
        return <MLModelConnectorForm value={dataSource}/>;

    }


    return (

                            <Space justify="space-between" direction="vertical" GapSm Full>
                                <Space direction="vertical" GapSm>



                                    <Space Wide Gap justify={"space-between"} align={"center"}>
                                        <Title>Models</Title>{btn()}
                                    </Space>


                                    <PaginatedCollectionList
                                        selectedCollection="model"
                                        renderItem={renderPostItem}
                                        actions={[
                                            {element:<FontAwesomeIcon icon={faArrowUpFromBracket} fixedWidth/>}
                                        ]}
                                    />




                                </Space>
                                <Space direction="vertical" justify="end">
                                    <Space GapSm><Pill>Customizable</Pill><Pill>Powerful</Pill></Space>
                                </Space>
                            </Space>

    );

};

export default MLModel;
