import React, {useEffect, useState} from "react";
import {Button, Card, Col, Row, Space, Title, Paragraph, Pill, Content, ButtonSize, Input} from "../layout/Content";
import {useNavigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAlignLeft, faArrowUpFromBracket, faPlus} from "@fortawesome/free-solid-svg-icons";
import PaginatedCollectionList from "./PaginatedCollectionList";
import FineTuneConnectorForm from "./FineTuneConnectorForm";
import {useUserProfileContext} from "../../lib/UserContext";
import {Blockquote, Collapsible} from "../layout/Defaults";
import {faCircleCheck, faCircleXmark, faEdit} from "@fortawesome/free-regular-svg-icons";
import CM from "./CodeMirror";
import StarRating from "../part/StarRating";
import {xcode} from "react-syntax-highlighter/dist/esm/styles/hljs";
import SyntaxHighlighter from "react-syntax-highlighter";

const renderPostItem = (log: any, size?: ButtonSize) => {

    if (size === "small") {
        return (
            <Card PadSm Wide>
                <Row GapSm>
                    <Col xs={24}>
                        <Space>
                           <Input Grow type={"text"} disabled value={log.data.prompt}/>
                        </Space>
                    </Col>
                    <Col xs={6}>
                        <Space direction={"vertical"} GapSm>
                            <strong>Schema</strong>
                            {(log.data.schema && log.data.schema.length>=1)?<FontAwesomeIcon fixedWidth icon={faAlignLeft}/>:<FontAwesomeIcon className={"muted-heavy"} fixedWidth icon={faCircleXmark}/>}
                        </Space>
                    </Col>
                    <Col xs={6}>
                        <Space direction={"vertical"} GapSm>
                            <strong>Data</strong>
                            {(log.data.data && log.data.data.length>=1)?<FontAwesomeIcon fixedWidth icon={faAlignLeft}/>:<FontAwesomeIcon className={"muted-heavy"} fixedWidth icon={faCircleXmark}/>}
                        </Space>
                    </Col>
                    <Col xs={6}>
                        <Space direction={"vertical"} GapSm>
                            <strong>Response</strong>
                            {(log.data.response && log.data.response.length>=1)?<FontAwesomeIcon fixedWidth icon={faAlignLeft}/>:<FontAwesomeIcon className={"muted-heavy"} fixedWidth icon={faCircleXmark}/>}
                        </Space>
                    </Col>
                    <Col xs={6}>
                        <Space direction={"vertical"} GapSm>
                            <strong>Results</strong>
                            {(log.data.results && log.data.results.length>=1)?<FontAwesomeIcon fixedWidth icon={faAlignLeft}/>:<FontAwesomeIcon className={"muted-heavy"} fixedWidth icon={faCircleXmark}/>}
                        </Space>
                    </Col>
                </Row>
            </Card>);
    }

    return (
        <Card Pad Wide>
            <Row GapSm>
                <Col xs={24}>
                    <Row GapSm>
                        <Col xs={24} md={12}>
                            <strong>Created At</strong>
                            <Paragraph>{log.createdAt || 'Unknown'}</Paragraph>
                        </Col>
                        <Col xs={12} md={4}>
                            <strong>Task</strong>
                            <Paragraph>{log.task || 'Unknown Task'}</Paragraph>
                        </Col>
                        <Col xs={12} md={8}>
                            <strong>Rating</strong>
                            <StarRating initialRating={log.labels?.rating || 0} onRatingChange={(e) => {
                                console.log('update partial rating', e);
                            }}/>
                        </Col>
                        <Col xs={24}>
                            <Space direction={"vertical"} GapSm>

                                {(log.data.schema && log.data.schema.length > 0) &&
                                    <Collapsible title={"Schema"}><Content PadSm>
                                        <SyntaxHighlighter
                                            language="json"
                                            style={xcode}
                                            wrapLongLines={true}
                                            customStyle={{
                                                fontSize:"9pt",
                                                borderRadius: "12px",
                                                padding: "16px",
                                                background: "#fcfcfc"
                                            }}
                                        >{JSON.stringify(log.data.schema,null," ")}</SyntaxHighlighter>
                                    </Content></Collapsible>}

                                {log.data.prompt&&<Col xs={24}>
                                    <Space direction={"vertical"} GapSm>
                                        <strong>User Prompt</strong>
                                        <Blockquote>{log.data.prompt}</Blockquote>
                                    </Space>
                                </Col>}

                                {(log.data.data && log.data.data.length > 0) && <Collapsible title={"Data"}><Content PadSm>
                                    <SyntaxHighlighter
                                        language="json"
                                        style={xcode}
                                        wrapLongLines={true}
                                        customStyle={{
                                            fontSize:"9pt",
                                            borderRadius: "12px",
                                            padding: "16px",
                                            background: "#fcfcfc"
                                        }}
                                    >{JSON.stringify(log.data.data.map((d:any[])=>d.slice(0,100)),null," ")}</SyntaxHighlighter>
                                </Content></Collapsible>}
                                <Collapsible title={"Response"}>
                                    <Content
                                        PadSm>{(log.data.response && (log.task === "results" || log.task === "alignment")) ?
                                        <SyntaxHighlighter
                                            language="json"
                                            style={xcode}
                                            wrapLongLines={true}
                                            customStyle={{
                                                fontSize:"9pt",
                                                borderRadius: "12px",
                                                padding: "16px",
                                                background: "#fcfcfc"
                                            }}
                                        >{(log.data.response)}</SyntaxHighlighter> : log.data.response}</Content>
                                </Collapsible>
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
};

const FineTune: React.FC = () => {
    const navigate = useNavigate();
    const {controller, uuid} = useParams<{ controller: string; uuid: string }>();
    const {getUserProfile} = useUserProfileContext();
    const [fineTune, setFineTune] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);

    // Fetch fine-tune data by UUID
    useEffect(() => {
        const fetchFineTune = async () => {
            if (controller === "edit" && uuid) {
                setLoading(true);
                try {
                    const response = await fetch(`https://api.clusterfx.org/finetune/${uuid}`, {
                        method: "GET",
                        mode: 'cors',
                        credentials: 'include',
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });

                    if (response.ok) {
                        const result = await response.json();
                        if (result.results.length > 0) {
                            setFineTune(result.results[0]);
                        }
                    }
                } catch (error) {
                    console.error("Failed to fetch fine-tune:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchFineTune();
    }, [controller, uuid]);

    const user = getUserProfile();

    // If adding a new fine-tune
    if (controller === "add") {
        return <FineTuneConnectorForm/>;
    }

    // Redirect or show loader if the fine-tune is loading or doesn't belong to the user
    if (loading) {
        return <div>Loading...</div>;
    }

    // If editing an existing fine-tune
    if (controller === "edit" && fineTune && user && fineTune.owner.uuid === user.uuid) {
        return <FineTuneConnectorForm finetune={fineTune}/>;
    }

    // if (fineTune && fineTune.owner.uuid !== user.uuid) {
    // navigate("/databox/tuning");
    // return null;
    //}

    return (
        <Space justify="space-between" direction="vertical" GapSm Full>
            <Space direction="vertical" GapSm>
                <Space Wide Gap justify={"space-between"} align={"center"}>
                    <Title>Fine-tuning</Title>
                    <Button type="primary" onClick={() => navigate("/databox/tuning/add")}>
                        <FontAwesomeIcon icon={faPlus} fixedWidth/> Add New Example
                    </Button>
                </Space>

                <PaginatedCollectionList
                    selectedCollection="finetune"
                    renderItem={renderPostItem}
                    actions={[
                        {element: <FontAwesomeIcon icon={faArrowUpFromBracket} fixedWidth/>},
                        {
                            element: <FontAwesomeIcon onClick={() => {
                                navigate("/databox/tuning/edit/" + uuid);
                            }} icon={faEdit} fixedWidth/>
                        }
                    ]}
                />
            </Space>
            <Space direction="vertical" justify="end">
                <Space GapSm>
                    <Pill>Customizable</Pill>
                    <Pill>Powerful</Pill>
                </Space>
            </Space>
        </Space>
    );
};

export default FineTune;
